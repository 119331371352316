import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';
import TeacherRatingReport from './TeacherRatingReport';
import TestScore from './TestScore';
import BookTest from './BookTest';

const RegistrationList = () => {
  const [attendanceData, setAttendanceData] = useState([]);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(150);
  const [activeTab, setActiveTab] = useState('attendance'); // Manage active tab
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) {
      console.error('Invalid date:', dateString);
      return 'Invalid date';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
  };

  const fetchAttendanceData = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/attendance`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      const sortedAttendance = response.data.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      setAttendanceData(sortedAttendance);
      setFilteredClasses(sortedAttendance);
    } catch (error) {
      console.error('Error fetching attendance data:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchAttendanceData();
  }, [fetchAttendanceData]);

  useEffect(() => {
    const filtered = attendanceData.filter(attendance =>
      attendance.student_nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredClasses(filtered);
    setCurrentPage(1);
  }, [searchTerm, attendanceData]);

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredClasses.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredClasses.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value)); // Update the number of items per page
    setCurrentPage(1); // Reset to the first page when items per page change
  };

    // Prepare CSV Data from paginated and filtered data
  const csvData = currentItems.map((attendance) => ({
    student: attendance.student_nickname,
    course: attendance.class_code,
    classid: attendance.classid,
    subject: attendance.subject_name,
    type: attendance.isprivate ? t('private') : t('group'),
    date: formatDate(attendance.date),
    attendance: attendance.status,
    comments: attendance.comment,
  }));

  return (
    <div>
      <Sidebar />
      <Header />

      <div>
        <div className="tabs-container">
          <button
            className={`tutor-tab-button ${activeTab === 'attendance' ? 'active' : ''}`}
            onClick={() => setActiveTab('attendance')}
          >
            {t('Attendance')}
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'teacherRating' ? 'active' : ''}`}
            onClick={() => setActiveTab('teacherRating')}
          >
            {t('Teacher Rating')}
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'bookTest' ? 'active' : ''}`}
            onClick={() => setActiveTab('bookTest')}
          >
            {t('Register for a Test')}
          </button>
          <button
            className={`tutor-tab-button ${activeTab === 'testScore' ? 'active' : ''}`}
            onClick={() => setActiveTab('testScore')}
          >
            {t('Student Test Scores')}
          </button>
        </div>

        <div className="main-content">
          {activeTab === 'attendance' && (
            <>
              <div className="search-container">
                <input
                  type="text"
                  placeholder={t('searchByName')}
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                 <div className="export-container">
                <CSVLink
                  data={csvData}
                  filename="attendance_data.csv"
                  className="export-button"
                  target="_blank"
                >
                  <FontAwesomeIcon icon={faFileExcel} />{t('Export CSV')}
                </CSVLink>
              </div>
              </div>

              <div className="pagination-controls">
                <label>{t('ItemsPerPage')}:</label>
                <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
                  <option value={150}>150</option>
                  <option value={200}>200</option>
                  <option value={300}>300</option>
                </select>
              </div>

              <div className="pagination-container">
                <button
                  onClick={handlePrevPage}
                  disabled={currentPage === 1}
                  className="page-button"
                >
                  {t('Prev')}
                </button>
                {Array.from({ length: totalPages }, (_, i) => (
                  <button
                    key={i + 1}
                    onClick={() => paginate(i + 1)}
                    className={`page-button ${i + 1 === currentPage ? 'active' : ''}`}
                  >
                    {i + 1}
                  </button>
                ))}
                <button
                  onClick={handleNextPage}
                  disabled={currentPage === totalPages}
                  className="page-button"
                >
                  {t('Next')}
                </button>
              </div>

              <table className="student-classes-table">
                <thead>
                  <tr>
                    <th>{t('student')}</th>
                    <th>{t('course')}</th>
                    <th>{t('classid')}</th>
                    <th>{t('subject')}</th>
                    <th>{t('type')}</th>
                    <th>{t('date')}</th>
                    <th>{t('attendance')}</th>
                    <th>{t('comments')}</th>
                  </tr>
                </thead>
                <tbody>
                  {currentItems.map((studentClass, index) => (
                    <tr key={studentClass.attendance_id || index}>
                      <td>{studentClass.student_nickname}</td>
                      <td>{studentClass.class_code}</td>
                      <td>{studentClass.classid}</td>
                      <td>{studentClass.subject_name}</td>
                      <td>{studentClass.isprivate ? t('private') : t('group')}</td>
                      <td>{formatDate(studentClass.date)}</td>
                      <td style={{ 
                          color: studentClass.status === 'Present' ? 'green' : 
                                studentClass.status === 'Absent' ? 'red' : 
                                studentClass.status === 'Late' ? 'orange' : 'black' 
                        }}>
                        {studentClass.status}
                      </td>
                      <td>{studentClass.comment}</td>
                    </tr>
                  ))}
                </tbody>

              </table>
            </>
          )}

          {activeTab === 'teacherRating' && <TeacherRatingReport />}
          {activeTab === 'bookTest' && <BookTest />}
          {activeTab === 'testScore' && <TestScore />}
        </div>
      </div>
    </div>
  );
};

export default withRoleAccess(RegistrationList, ['admin', 'superadmin']);