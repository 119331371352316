import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';
import moment from 'moment';
import { debounce } from 'lodash'; 
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faTrash } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import FullCalendar from '@fullcalendar/react';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import Modal from '../../components/Modal';
import './Classes.css';
import { refreshToken, isTokenExpiredError } from '../../Utils/authUtils';

const hourOptions = [0, 1, 2, 3];
const minuteOptions = [0, 15, 30, 45];


const calculateEndDate = (startDate, recurrences, recurring) => {
  if (!startDate) {
    console.error('Invalid startDate:', startDate);
    return ''; // Return an empty string or a default value if startDate is invalid
  }
  
  // Check if the startDate is a valid date
  const parsedStartDate = new Date(startDate);
  if (isNaN(parsedStartDate.getTime())) {
    console.error('Invalid date format:', startDate);
    return ''; // Return an empty string if the date format is invalid
  }
  
  if (recurrences <= 0) {
    console.error('Recurrences must be greater than 0:', recurrences);
    return startDate; // No recurrence, return the same start date
  }
  
  let endDate;
  if (recurring === 'daily') {
    endDate = new Date(parsedStartDate.setDate(parsedStartDate.getDate() + (recurrences - 1)));
  } else if (recurring === 'weekly') {
    endDate = new Date(parsedStartDate.setDate(parsedStartDate.getDate() + (recurrences - 1) * 7));
  } else {
    console.error('Invalid recurring value:', recurring);
    return ''; // Return empty if the recurring value is invalid
  }

  return endDate.toISOString().split('T')[0];
};

const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const formatDateTime = (date, time) => {
  return `${moment(date).format('YYYY-MM-DD')}T${time}`;
};

function Classes() {
  const { t, i18n } = useTranslation();
  const [teachers, setTeachers] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [classrooms, setClassrooms] = useState([]);
  const initialNewClassState = {
    class_code: '',
    classDetails: [
      {
        subject_id: '',
        teacher_id: '',
        schedule: [
          { day: '', time: '', hours: '0', minutes: '0', recurring: 'none', number_of_recurrences: 1 }
        ],
        total_hours: 0,
      }
    ],
    start_date: '',
    end_date: '',
    classroom_id: '',
    price: 0,
    register_capacity: 0,
    isprivate: false,
    is_template: false,
    create_date: new Date().toISOString().split('T')[0]
  };
  const [newClass, setNewClass] = useState(initialNewClassState);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [confirmingClass, setConfirmingClass] = useState(null);
  const [teacherClasses, setTeacherClasses] = useState({});
  const [teacherSchedules, setTeacherSchedules] = useState({});
  const [showTeacherCalendars, setShowTeacherCalendars] = useState({});

  const API_URL = process.env.REACT_APP_API_URL;


  const fetchTeachers = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeachers(response.data);
      setLoading(false);
    } catch (error) {
      if (isTokenExpiredError(error)) {
        refreshToken().then(() => {
          fetchTeachers(); 
        }).catch(err => {
          console.error('Token refresh failed:', err);
          setError('Session expired. Please log in again.');
        });
      } else {
        console.error('Error fetching teachers:', error);
        setError('Error fetching teachers. Please try again later.');
      }
      setLoading(false);
    }
  }, [API_URL]);

  const fetchSubjects = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/subjects`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setSubjects(response.data);
    } catch (error) {
      console.error('Error fetching subjects:', error);
    }
  }, [API_URL]);

  const fetchClassrooms = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classrooms`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setClassrooms(response.data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching classrooms:', error);
      setError('Failed to fetch classrooms. Please try again later.');
      setLoading(false);
    }
  }, [API_URL]);

  const fetchTeacherClasses = useCallback(async (teacherId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher/${teacherId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
  
      const transformedClasses = response.data.map(cls => ({
        id: cls.classid,
        title: cls.subject_name,
        start: formatDateTime(cls.date, cls.schedule_time),
        end: formatDateTime(cls.date, cls.end_time),
        type: 'class'
      }));
  
      // Use filter to remove duplicates
      const distinctClasses = transformedClasses.filter((cls, index, self) =>
        index === self.findIndex(c => c.id === cls.id)
      );
  
      setTeacherClasses(prevClasses => ({
        ...prevClasses,
        [teacherId]: distinctClasses // Store distinct classes
      }));
    } catch (error) {
      console.error('Failed to fetch teacher classes:', error);
    }
  }, [API_URL]);
  
  const fetchTeacherSchedule = useCallback(async (teacherId) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/teachers/${teacherId}/events`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeacherSchedules(prevSchedules => ({
        ...prevSchedules,
        [teacherId]: response.data
      }));
      setShowTeacherCalendars(prevShowCalendars => ({
        ...prevShowCalendars,
        [teacherId]: true
      }));
    } catch (error) {
      console.error('Error fetching teacher schedule:', error);
      setError('Failed to fetch teacher schedule. Please try again later.');
    }
  }, [API_URL]);
  
  // Call these inside useEffect
  useEffect(() => {
    fetchTeachers();
    fetchSubjects();
    fetchClassrooms();
  }, [fetchTeachers, fetchSubjects, fetchClassrooms, API_URL]);
   

  useEffect(() => {
    newClass.classDetails.forEach(detail => {
      if (detail.teacher_id) {
        fetchTeacherSchedule(detail.teacher_id);
        fetchTeacherClasses(detail.teacher_id);
      }
    });
  }, [newClass.classDetails, fetchTeacherSchedule, fetchTeacherClasses]);

  const updateTotalHoursAndEndDate = (updatedClassDetails) => {
    const totalHours = updatedClassDetails.reduce((total, detail) => {
      return total + calculateTotalHours(detail.schedule, detail.number_of_recurrences);
    }, 0);

    const endDate = updatedClassDetails.map(detail => 
      calculateEndDate(newClass.start_date, detail.number_of_recurrences, newClass.recurring)
    );

    setNewClass(prevClass => ({
      ...prevClass,
      classDetails: updatedClassDetails,
      total_hours: totalHours,
      end_date: endDate[endDate.length - 1] // Set the end date to the latest one
    }));
  };

  const handleNewClassChange = (e, index = 0) => {
    const { name, value } = e.target;
    setNewClass((prevClass) => {
      const updatedClassDetails = [...prevClass.classDetails];
  
      if (name === 'class_code' || name === 'start_date' || name === 'recurring' || name === 'classroom_id' || name === 'book_details_materials' || name === 'isprivate' || name === 'price' || name === 'register_capacity' || name === 'is_template') {
        let updatedClass = { ...prevClass, [name]: value };
  
        // Set 'isprivate' to false if not checked
        if (name === 'isprivate') {
          updatedClass = { ...prevClass, isprivate: !prevClass.isprivate };
        }
  
        if (name === 'recurring') {
          // Initialize schedule array if recurrence is 'none' or 'daily'
          if (value === 'none' || value === 'daily') {
            updatedClass.classDetails[index].schedule = [{ time: '', hours: '0', minutes: '0' }];
          }
        }
  
        updateTotalHoursAndEndDate(updatedClassDetails);
        return updatedClass;
      } else {
        updatedClassDetails[index] = {
          ...updatedClassDetails[index],
          [name]: value,
        };
  
        updateTotalHoursAndEndDate(updatedClassDetails);
        return { ...prevClass, classDetails: updatedClassDetails };
      }
    });
  };
  

// Ensure recurrence is handled at the time slot level in handleScheduleChange
const handleScheduleChange = (index, scheduleIndex, field, value) => {
  const updatedClassDetails = [...newClass.classDetails];
  const updatedSchedule = [...updatedClassDetails[index].schedule];

  updatedSchedule[scheduleIndex] = {
    ...updatedSchedule[scheduleIndex],
    [field]: value,  // Handle the recurrence field correctly
  };

  updatedClassDetails[index] = {
    ...updatedClassDetails[index],
    schedule: updatedSchedule,
  };

  // Update total hours and end date based on recurrence at the slot level
  updateTotalHoursAndEndDate(updatedClassDetails);
};

// Adjust the calculateTotalHours function to factor in the recurrence per timeslot
const calculateTotalHours = (schedule) => {
  return schedule.reduce((total, slot) => {
    const hours = parseInt(slot.hours, 10);
    const minutes = parseInt(slot.minutes, 10);
    const recurrences = parseInt(slot.number_of_recurrences || 1, 10); // Default to 1 if not set
    return total + (hours + (minutes / 60)) * recurrences;
  }, 0);
};

const handleAddScheduleSlot = useCallback((classIndex) => {

  setNewClass(prevClass => {
    const updatedClassDetails = [...prevClass.classDetails];
    const updatedSchedule = [...updatedClassDetails[classIndex].schedule];
    updatedSchedule.push({
      day: '', 
      time: '', 
      hours: '0', 
      minutes: '0', 
      recurring: 'none', 
      number_of_recurrences: 1
    });
    updatedClassDetails[classIndex] = {
      ...updatedClassDetails[classIndex],
      schedule: updatedSchedule
    };
    return {
      ...prevClass,
      classDetails: updatedClassDetails
    };
  });
}, []);

const debouncedHandleAddScheduleSlot = debounce((classIndex) => {
  handleAddScheduleSlot(classIndex);
}, 300);


  const handleAddClassDetail = () => {
    setNewClass((prevClass) => {
      const updatedClassDetails = [
        ...prevClass.classDetails,
        {
          subject_id: '',
          teacher_id: '',
          schedule: [],
          total_hours: 0,
          number_of_recurrences: 1
        }
      ];
      updateTotalHoursAndEndDate(updatedClassDetails);
      return {
        ...prevClass,
        classDetails: updatedClassDetails
      };
    });
  };

  const handleSaveClass = async (classData) => {
    const token = localStorage.getItem('token');
    try {
      await axios.post(`${API_URL}/classes`, classData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setConfirmationMessage('Class created successfully');
      setNewClass(initialNewClassState); // Reset the form fields here
      setShowConfirmationModal(true); // Show confirmation modal only for save actions
    } catch (error) {
      console.error('Error saving class:', error.response?.data || error);
      setError('Failed to save class.');
    }
  };
  
  const handleConfirmSubmit = async () => {
    await handleSaveClass(newClass);
    setShowConfirmationModal(false);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    // Ensure isprivate is set to false if it's not selected
    const updatedClass = {
      ...newClass,
      isprivate: newClass.isprivate === undefined || newClass.isprivate === null ? false : newClass.isprivate
    };
  
    if (!updatedClass.start_date) {
      setError('Please set a start date before adding schedule slots.');
      return;
    }
    if (updatedClass.classDetails.some(detail => !detail.teacher_id || !detail.subject_id)) {
      setError('Please select a teacher and a subject for all class details.');
      return;
    }
  
    setConfirmingClass(updatedClass);
    setShowConfirmationModal(true);
  };
  

  const handleDeleteScheduleSlot = (classIndex, scheduleIndex) => {
    const updatedClassDetails = [...newClass.classDetails];
    const updatedSchedule = [...updatedClassDetails[classIndex].schedule];
    updatedSchedule.splice(scheduleIndex, 1);
    updatedClassDetails[classIndex] = {
      ...updatedClassDetails[classIndex],
      schedule: updatedSchedule
    };

    updateTotalHoursAndEndDate(updatedClassDetails);
  };

  const handleDeleteClassDetail = (index) => {
    setNewClass((prevClass) => {
      const updatedClassDetails = [...prevClass.classDetails];
      updatedClassDetails.splice(index, 1);
      return {
        ...prevClass,
        classDetails: updatedClassDetails
      };
    });
  };
  

  const combinedEvents = (teacherId) => {
    
    return [
      ...(teacherSchedules[teacherId] || []).map(event => ({
        title: `${event.title} (${formatTime(event.start)} - ${formatTime(event.end)})`,
        start: event.start,
        end: event.end,
        type: 'schedule'
      })),
      ...(teacherClasses[teacherId] || []).map(cls => ({
        title: cls.title,
        start: cls.start,
        end: cls.end,
        type: 'class'
      }))
    ];
  };

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <div>
      <Sidebar />
      <Header />
      <div>
        <div className="form-and-preview">
        <div className="new-class-container">
            <h2>{t('createNewCourse')}</h2>
            <form onSubmit={handleSubmit}>
              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="class_code">{t('classCode')}</label>
                  <input
                    type="text"
                    name="class_code"
                    value={newClass.class_code}
                    onChange={handleNewClassChange}
                    placeholder={t('classCode')}
                    required
                  />
                </div>
              </div>

              {newClass.classDetails.map((detail, index) => (
                <div className="form-wrap" key={index}>
                  <div className="form-row">
                    <div className="form-group">
                      <label htmlFor={`subject_id-${index}`}>{t('subject')}</label>
                      <select
                        name="subject_id"
                        value={detail.subject_id}
                        onChange={(e) => handleNewClassChange(e, index)}
                        required
                      >
                        <option value="">{t('selectSubject')}</option>
                        {subjects.map((subject) => (
                          <option key={subject.subjectid} value={subject.subjectid}>
                            {subject.title}
                          </option>
                        ))}
                      </select>
                    </div>

                    <div className="form-group">
                      <label htmlFor={`teacher_id-${index}`}>{t('teacher')}</label>
                      <select
                        name="teacher_id"
                        value={detail.teacher_id}
                        onChange={(e) => handleNewClassChange(e, index)}
                        required
                      >
                        <option value="">{t('selectTeacher')}</option>
                        {teachers.map((teacher) => (
                          <option key={teacher.userid} value={teacher.userid}>
                            {teacher.nickname} {teacher.firstname}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="form-row">
                    <button
                      type="button"
                      onClick={() => debouncedHandleAddScheduleSlot(index)}
                      className="add-time-slot"
                    >
                      {t('addTimeSlot')}
                    </button>
                  </div>

                  {detail.schedule.map((slot, scheduleIndex) => (
                      <div className="form-row schedule-slot" key={scheduleIndex}>
                        {/* Column 1: Day and Time */}
                        <div className="form-group">
                          <label htmlFor={`day-${index}-${scheduleIndex}`}>{t('day')}</label>
                          <select
                            name="day"
                            value={slot.day}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'day', e.target.value)}
                            required
                          >
                            <option value="">{t('selectDay')}</option>
                            <option value="Monday">{t('monday')}</option>
                            <option value="Tuesday">{t('tuesday')}</option>
                            <option value="Wednesday">{t('wednesday')}</option>
                            <option value="Thursday">{t('thursday')}</option>
                            <option value="Friday">{t('friday')}</option>
                            <option value="Saturday">{t('saturday')}</option>
                            <option value="Sunday">{t('sunday')}</option>
                          </select>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor={`time-${index}-${scheduleIndex}`}>{t('time')}</label>
                          <input
                            type="time"
                            name="time"
                            value={slot.time}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'time', e.target.value)}
                            required
                            min="06:00"
                            max="21:00"
                          />
                        </div>
                        
                        {/* Column 2: Hours and Minutes */}
                        <div className="form-group">
                          <label htmlFor={`hours-${index}-${scheduleIndex}`}>{t('hours')}</label>
                          <select
                            name="hours"
                            value={slot.hours || '0'}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'hours', e.target.value)}
                            required
                          >
                            {hourOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor={`minutes-${index}-${scheduleIndex}`}>{t('minutes')}</label>
                          <select
                            name="minutes"
                            value={slot.minutes || '0'}
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'minutes', e.target.value)}
                            required
                          >
                            {minuteOptions.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                            ))}
                          </select>
                        </div>
                        
                        {/* Column 3: Recurring and Recurrences */}
                        <div className="form-group">
                          <label htmlFor={`recurring-${index}-${scheduleIndex}`}>{t('recurring')}</label>
                          <select
                            name="recurring"
                            value={slot.recurring || 'none'}
                            onChange={(e) => {
                              handleScheduleChange(index, scheduleIndex, 'recurring', e.target.value);
                              if (e.target.value === 'none') {
                                handleScheduleChange(index, scheduleIndex, 'number_of_recurrences', 1); // Set recurrences to 1 when 'none'
                              }
                            }}
                          >
                            <option value="none">{t('oneClass')}</option>
                            <option value="daily">{t('daily')}</option>
                            <option value="weekly">{t('weekly')}</option>
                          </select>
                        </div>
                        
                        <div className="form-group">
                          <label htmlFor={`number_of_recurrences-${index}-${scheduleIndex}`}>
                            {t('numberOfRecurrences')}
                          </label>
                          <input
                            type="number"
                            name="number_of_recurrences"
                            value={slot.recurring === 'none' ? 1 : slot.number_of_recurrences}  // Force 1 when recurring is 'none'
                            onChange={(e) => handleScheduleChange(index, scheduleIndex, 'number_of_recurrences', e.target.value)}
                            min="1"
                            required
                            disabled={slot.recurring === 'none'}  // Disable input when recurring is 'none'
                          />
                        </div>
                        
                        <div>
                          <button
                            type="button"
                            onClick={() => handleDeleteScheduleSlot(index, scheduleIndex)}
                            className="delete-button"
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      </div>
                    ))}


                  <button type="button" onClick={() => handleDeleteClassDetail(index)} className="delete-time-slot">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>
              ))}

              <button type="button" onClick={handleAddClassDetail} className="add-class-detail">
                {t('addAnotherSubjectTeacher')}
              </button>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="start_date">{t('startDate')}</label>
                  <input
                    type="date"
                    name="start_date"
                    value={newClass.start_date}
                    onChange={handleNewClassChange}
                    placeholder={t('startDate')}
                    required
                  />
                </div>
                <div className="form-group end-date">
                  <label htmlFor="end_date">{t('endDate')}</label>
                  <input type="date" name="end_date" value={newClass.end_date} readOnly placeholder={t('endDate')} />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group total-hours">
                  <label htmlFor="total_hours">{t('totalHours')}</label>
                  <input type="number" name="total_hours" value={(newClass.total_hours || 0).toFixed(2)} placeholder={t('totalHours')} readOnly />
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="classroom_id">{t('selectClassroom')}</label>
                  <select name="classroom_id" value={newClass.classroom_id} onChange={handleNewClassChange}>
                    <option value="">{t('selectClassroom')}</option>
                    {classrooms.map((classroom) => (
                      <option key={classroom.classroomid} value={classroom.classroomid}>
                        {classroom.number}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label htmlFor="book_details_materials">{t('bookDetailsMaterials')}</label>
                  <textarea name="book_details_materials" value={newClass.book_details_materials} onChange={handleNewClassChange} placeholder={t('bookDetailsMaterials')}></textarea>
                </div>
              </div>

              <div className="form-row">
                <div className="form-group">
                  <label htmlFor="price">{t('price')}</label>
                  <input type="number" name="price" value={newClass.price} onChange={handleNewClassChange} placeholder={t('price')} required min="0" />
                </div>

                <div className="form-group">
                  <label htmlFor="register_capacity">{t('registerCapacity')}</label>
                  <input type="number" name="register_capacity" value={newClass.register_capacity} onChange={handleNewClassChange} placeholder={t('registerCapacity')} required min="0" />
                </div>
              </div>

              <div className="form-row checkbox-container">
                <label>
                  <input type="checkbox" name="isprivate" checked={newClass.isprivate} onChange={() => setNewClass({ ...newClass, isprivate: !newClass.isprivate })} />
                  <span className="checkmark"></span>
                  <h3>{t('checkPrivateClass')}</h3>
                </label>
              </div>

              <div className="form-row">
                <button type="submit" className="submit-button">{t('createCourse')}</button>
              </div>
            </form>
          </div>

          <div className="teacher-calendar-container">
            {newClass.classDetails.map((detail, index) => (
              <div key={index}>
                {showTeacherCalendars[detail.teacher_id] && (
                  <div>
                    <h3>{t('teacher')} {teachers.find(teacher => teacher.userid === detail.teacher_id)?.firstname}</h3>
                    <FullCalendar
                      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                      initialView="timeGridWeek"
                      allDaySlot={false}
                      locale={i18n.language === 'th' ? thLocale : 'en'} // Apply the locale
                      headerToolbar={{
                        left: 'prev,next today',
                        center: 'title',
                        right: 'dayGridMonth,timeGridWeek,timeGridDay',
                      }}
                      events={combinedEvents(detail.teacher_id)}
                      eventClassNames={(info) => {
                        if (info.event.extendedProps.type === 'class') {
                          return ['class-event'];
                        } else {
                          return ['schedule-event'];
                        }
                      }}
                      height={787}
                      slotMinTime="06:00:00"
                      slotMaxTime="20:00:00"
                    />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        {showConfirmationModal && (
          <div className="confirmation-modal">
            <div className="modal-content">
              <h2>{t('confirmClassDetails')}</h2>
              <table>
              <tbody>
                <tr>
                  <td><strong>{t('scheduleByDate')}:</strong></td>
                  <td>
                    <ul>
                      {confirmingClass.classDetails.flatMap((detail, classIndex) =>
                        detail.schedule.flatMap((slot, slotIndex) => {
                          // Ensure teacher_id and subject_id are numbers for proper matching
                          const teacher = teachers.find(teacher => teacher.userid === Number(detail.teacher_id));
                          const subject = subjects.find(sub => sub.subjectid === Number(detail.subject_id));

                          const teacherName = teacher ? `${teacher.firstname} ${teacher.lastname}` : 'Unknown Teacher';
                          const subjectName = subject ? subject.title : 'Unknown Subject';

                          // Assuming you have start_date and recurrence logic
                          const startDate = moment(confirmingClass.start_date).format('YYYY-MM-DD');
                          let recurrenceDates = [];

                          // Weekly or daily recurrence logic
                          if (slot.recurring === 'weekly') {
                            for (let i = 0; i < slot.number_of_recurrences; i++) {
                              const date = moment(startDate).add(i, 'weeks').format('YYYY-MM-DD');
                              recurrenceDates.push(date);
                            }
                          } else if (slot.recurring === 'daily') {
                            for (let i = 0; i < slot.number_of_recurrences; i++) {
                              const date = moment(startDate).add(i, 'days').format('YYYY-MM-DD');
                              recurrenceDates.push(date);
                            }
                          } else {
                            recurrenceDates.push(startDate); // For non-recurring, just push the start date
                          }

                          // Now map over the recurrenceDates to display each time slot
                          return recurrenceDates.map((recDate, idx) => {
                            const startTime = moment(slot.time, 'HH:mm');
                            const endTime = startTime.add(slot.hours, 'hours').format('HH:mm'); // Calculate end time

                            return (
                              <li key={`${classIndex}-${slotIndex}-${idx}`}>
                                <strong>{t('date')}:</strong> {moment(recDate).format('DD-MMM-YYYY')}, 
                                <strong>{t('time')}:</strong> {slot.time} - {endTime}, {/* Show both start and end times */}
                                <strong>{t('tutor')}:</strong> {teacherName}, 
                                <strong>{t('subject')}:</strong> {subjectName}
                              </li>
                            );
                          });
                        })
                      )}
                    </ul>
                  </td>
                </tr>
              </tbody>

              </table>
              <button onClick={handleConfirmSubmit} className="confirm-button">{t('confirm')}</button>
              <button onClick={() => setShowConfirmationModal(false)} className="modal-close-icon"><FontAwesomeIcon icon={faTimes} /></button>
            </div>
          </div>
        )}
      </div>
      {confirmationMessage && (
          <Modal isOpen={true} onClose={() => setConfirmationMessage('')}>
            <p className="confirmation-message">{confirmationMessage}</p>
          </Modal>
        )}
    </div>
  );
}

export default withRoleAccess(Classes, ['admin', 'superadmin']);
