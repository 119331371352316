import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { CSVLink } from 'react-csv';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';

const TeacherRatingReport = () => {
  const [teacherRatings, setTeacherRatings] = useState([]);
  const [filteredTeacherRatings, setFilteredTeacherRatings] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(150);
  const [searchTerm, setSearchTerm] = useState('');
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    if (isNaN(date)) {
      console.error('Invalid date:', dateString);
      return 'Invalid date';
    }
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(t('locale'), options).format(date).toUpperCase();
  };

  useEffect(() => {
    const fetchTeacherRatings = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/ratings`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTeacherRatings(response.data);
      } catch (error) {
        console.error('Error fetching teacher ratings:', error);
      }
    };
    fetchTeacherRatings();
  }, [API_URL]);

  useEffect(() => {
    const filtered = teacherRatings.filter((rating) =>
      rating.teacher_nickname.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredTeacherRatings(filtered);
    setCurrentPage(1);
  }, [searchTerm, teacherRatings]);

  // Pagination logic
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredTeacherRatings.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(filteredTeacherRatings.length / itemsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  const handlePrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  const handleNextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
  const handleItemsPerPageChange = (e) => {
    setItemsPerPage(Number(e.target.value));
    setCurrentPage(1);
  };

  // Prepare CSV data from the current paginated and filtered items
  const csvData = currentItems.map((rating) => ({
    teacher: rating.teacher_nickname,
    classCode: rating.class_code,
    subject: rating.subject_name,
    date: formatDate(rating.date),
    student: rating.student_nickname,
    rating: rating.teacher_rating,
    comments: rating.studentcomment,
    timestamp: formatDate(rating.timestamp),
  }));

  return (
    <div className="teacher-rating-container">
      <Sidebar />
      <Header />

      <h1>{t('Teacher Rating Report')}</h1>

      <div className="search-container">
        <input
          type="text"
          placeholder={t('Search by Teacher Name')}
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
          {/* CSV Export Button */}
      <div className="export-container">
        <CSVLink
          data={csvData} // Use the filtered and paginated data
          filename="teacher_rating_report.csv"
          className="export-button"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFileExcel} />{t('Export CSV')}
        </CSVLink>
      </div>
      </div>

      <div className="pagination-controls">
        <label>{t('Items per Page')}:</label>
        <select value={itemsPerPage} onChange={handleItemsPerPageChange}>
          <option value={150}>150</option>
          <option value={200}>200</option>
          <option value={250}>250</option>
        </select>
      </div>

    

      <div className="pagination-container">
        <button onClick={handlePrevPage} disabled={currentPage === 1} className="page-button">
          {t('Prev')}
        </button>
        {Array.from({ length: totalPages }, (_, i) => (
          <button
            key={i + 1}
            onClick={() => paginate(i + 1)}
            className={`page-button ${i + 1 === currentPage ? 'active' : ''}`}
          >
            {i + 1}
          </button>
        ))}
        <button onClick={handleNextPage} disabled={currentPage === totalPages} className="page-button">
          {t('Next')}
        </button>
      </div>

      <table className="student-classes-table">
        <thead>
          <tr>
            <th>{t('Teacher')}</th>
            <th>{t('Class Code')}</th>
            <th>{t('Subject')}</th>
            <th>{t('Date')}</th>
            <th>{t('Student')}</th>
            <th>{t('Rating')}</th>
            <th>{t('Comments')}</th>
            <th>{t('Timestamp')}</th>
          </tr>
        </thead>
        <tbody>
          {currentItems.map((rating, index) => (
            <tr key={index}>
              <td>{rating.teacher_nickname}</td>
              <td>{rating.class_code}</td>
              <td>{rating.subject_name}</td>
              <td>{formatDate(rating.date)}</td>
              <td>{rating.student_nickname}</td>
              <td>{rating.teacher_rating}</td>
              <td>{rating.studentcomment}</td>
              <td>{formatDate(rating.timestamp)}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default withRoleAccess(TeacherRatingReport, ['admin', 'superadmin']);
