import React, { useState, useEffect } from 'react';
import moment from 'moment';
import getUserIdFromToken from '../../Utils/authUtils';
import { createTask, updateTask, deleteTask } from './apiService';
import './Calendar.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { v4 as uuidv4 } from 'uuid'; // To generate unique groupId
import { useTranslation } from 'react-i18next';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css


const TaskPopup = ({ isOpen, task, onClose, onSave, date, tasks = [], classes = [] }) => {
  const { t } = useTranslation();
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [recurrence, setRecurrence] = useState('none');
  const [recurrenceCount, setRecurrenceCount] = useState(1);
  const [showConfirm, setShowConfirm] = useState(false); // State for showing the confirmation box
  const [groupId, setGroupId] = useState(task ? task.groupId : uuidv4());

  useEffect(() => {
    if (task) {
      setTitle(task.title || '');
      setDescription(task.description || '');
      setRecurrence(task.recurrence || 'none');
      setRecurrenceCount(task.recurrenceCount || 1);
      setGroupId(task.groupId || uuidv4());
    } else {
      setTitle('');
      setDescription('');
      setRecurrence('none');
      setRecurrenceCount(1);
      setGroupId(uuidv4());
    }
  }, [task, isOpen]);

  const handleSaveTask = async () => {
    const userId = getUserIdFromToken();
    if (!userId) {
      console.error('User ID not found, user might not be logged in');
      return;
    }
  
    const taskStart = task ? moment(task.start) : moment(date.start);
    const taskEnd = task ? moment(task.end) : moment(date.end);
  
    // Check for any overlap with existing classes
    const isOverlapping = classes.some(cls => {
      const classStart = moment(cls.start);
      const classEnd = moment(cls.end);
  
      // Overlap check: task start or end falls within the class time, or fully overlaps
      return (
        taskStart.isBetween(classStart, classEnd, null, '[)') || 
        taskEnd.isBetween(classStart, classEnd, null, '(]') || 
        (taskStart.isBefore(classStart) && taskEnd.isAfter(classEnd)) // full overlap
      );
    });
  
    if (isOverlapping) {
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => {}
          }
        ]
      });
      return; // Prevent task creation if overlap exists
    }
  
    // Proceed with saving the task if no overlap
    const taskDetails = {
      userId,
      title,
      description,
      start: task ? moment(task.start).format('YYYY-MM-DDTHH:mm:ss') : date ? moment(date.start).format('YYYY-MM-DDTHH:mm:ss') : null,
      end: task ? moment(task.end).format('YYYY-MM-DDTHH:mm:ss') : date ? moment(date.end).format('YYYY-MM-DDTHH:mm:ss') : null,
      recurrence: task ? task.recurrence : recurrence,
      recurrenceCount,
      groupId: task ? task.groupId : groupId,
    };
  
    try {
      if (task) {
        // Update existing task
        const taskId = task.id.replace(/^task-/, '').replace(/^class-/, '');
        await updateTask(taskId, taskDetails);
      } else {
        // Create new task
        await createTask(taskDetails);
      }
      onSave();
      onClose();
    } catch (error) {
      console.error('Error saving task:', error);
    }
  };
  
  

  const handleDeleteTask = async () => {
    try {
      // Strip prefix before deleting
      const taskId = task.id.replace(/^task-/, '').replace(/^class-/, '');
      await deleteTask(taskId);
      onSave();
      onClose();
    } catch (error) {
      console.error('Error deleting task:', error);
    }
    onClose();
  };
  

  if (!isOpen) return null;

  return (
    <div className="popup-overlay">
      <div className="popup">
        <button className="close-button" onClick={onClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <h2>{task ? t('editTask') : t('newTask')}</h2>
        <label>{t('title')}:</label>
        <input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
        <label>{t('description')}:</label>
        <textarea value={description} onChange={(e) => setDescription(e.target.value)} />
        <label>{t('date')}:</label>
        <input type="text" readOnly value={task ? moment(task.start).format('YYYY-MM-DD') : date ? moment(date.start).format('YYYY-MM-DD') : ''} />
        <label>{t('startTime')}:</label>
        <input type="text" readOnly value={task ? moment(task.start).format('HH:mm') : date ? moment(date.start).format('HH:mm') : ''} />
        <label>{t('endTime')}:</label>
        <input type="text" readOnly value={task ? moment(task.end).format('HH:mm') : date ? moment(date.end).format('HH:mm') : ''} />
        <label>{t('recurrence')}:</label>
        <select value={recurrence} onChange={(e) => setRecurrence(e.target.value)}>
          <option value="none">{t('none')}</option>
          <option value="daily">{t('daily')}</option>
          <option value="weekly">{t('weekly')}</option>
          <option value="bi-weekly">{t('biWeekly')}</option>
          <option value="monthly">{t('monthly')}</option>
        </select>
        {recurrence !== 'none' && (
          <>
            <label>{t('numberOfRecurrences')}:</label>
            <input type="number" value={recurrenceCount} onChange={(e) => setRecurrenceCount(e.target.value)} min="1" />
          </>
        )}
        <div className="popup-buttons">
          <button className="save-button" onClick={handleSaveTask}>{t('save')}</button>
          {task && <button className="delete-button" onClick={() => setShowConfirm(true)}>{t('delete')}</button>}
        </div>
      </div>
      {showConfirm && (
        <div className="confirm-box">
          <p>{t('confirmDeleteTask')}</p>
          <button onClick={handleDeleteTask}>{t('yes')}</button>
          <button onClick={() => setShowConfirm(false)}>{t('no')}</button>
          
        </div>
      )}
    </div>
  );
};

export default TaskPopup;
