import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileInvoice, faPrint } from '@fortawesome/free-solid-svg-icons';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';
import SignatureCanvas from 'react-signature-canvas';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import { sarabunFont } from '../../sarabunFont';
import CreatableSelect from 'react-select/creatable'; 
import './Registrations.css';

// Function to load the Sarabun font into jsPDF
const loadSarabunFont = (doc) => {
  doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
  doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
  doc.setFont('Sarabun');
};

const formatDate = (dateString) => {
  if (!dateString) return 'N/A';
  const date = new Date(dateString);
  if (isNaN(date.getTime())) return 'N/A';
  const options = { day: '2-digit', month: 'short', year: 'numeric' };
  return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
};

const formatTime = (timeString) => {
  const [hours, minutes] = timeString.split(':');
  return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
};

const formatNumber = (number) => {
  const parsedNumber = parseFloat(number);
  if (isNaN(parsedNumber)) {
      return '0.00';
  }
  return new Intl.NumberFormat('en-US', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
  }).format(parsedNumber);
};



const Registrations = () => {
  const { t } = useTranslation();
  const [students, setStudents] = useState([]);
  const [classes, setClasses] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState('');
  const [studentDetails, setStudentDetails] = useState({});
  const [selectedClass, setSelectedClass] = useState('');
  const [schedule, setSchedule] = useState([]);
  const [promotion, setPromotion] = useState('');
  const [price, setPrice] = useState(0);
  const [inventoryPrice, setInventoryPrice] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('');
  const [memo, setMemo] = useState('');
  const [isPaymentConfirmed, setIsPaymentConfirmed] = useState(false);
  const [vat, setVat] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [confirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const sigCanvas = useRef(null);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedInventoryItems, setSelectedInventoryItems] = useState([]);
  const [baseClassPrice, setBaseClassPrice] = useState(0);
  const [priceBeforeVat, setPriceBeforeVat] = useState(0);


  const API_URL = process.env.REACT_APP_API_URL;

  const calculateEndTime = (startTime, scheduleHour) => {
    // Split the scheduleHour into hours and minutes (e.g., '2h 30m' => 2 hours, 30 minutes)
    const hourParts = scheduleHour.split(' '); 
    const hours = parseInt(hourParts[0], 10); // Extract hours from '2h'
    const minutes = hourParts.length > 1 ? parseInt(hourParts[1], 10) : 0; // Extract minutes if present
  
    // Split startTime (format 'HH:mm') into hours and minutes
    const [startHour, startMinute] = startTime.split(':').map(Number);
  
    // Use moment to add the hours and minutes to the start time
    const startMoment = moment().hours(startHour).minutes(startMinute);
  
    // Add the extracted hours and minutes to calculate the end time
    const endMoment = startMoment.add(hours, 'hours').add(minutes, 'minutes');
  
    // Return the formatted end time as 'HH:mm'
    return endMoment.format('HH:mm');
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
  };
  

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const studentsData = response.data.students || [];
      setStudents(studentsData);
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);
  

  const fetchClasses = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/class-groups`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const classesData = Array.isArray(response.data) ? response.data : [];
      setClasses(classesData);
    } catch (error) {
      console.error('Error fetching classes:', error);
    }
  }, [API_URL]);

  const fetchInventoryItems = useCallback(async () => {
    try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/inventory`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });
        const inventoryData = response.data || [];
        setInventoryItems(inventoryData);
    } catch (error) {
        console.error('Error fetching inventory items:', error);
    }
}, [API_URL]);

useEffect(() => {
    fetchStudents();
    fetchClasses();
    fetchInventoryItems(); // Fetch inventory items when component mounts
}, [fetchStudents, fetchClasses, fetchInventoryItems]);

const studentOptions = students.map(student => ({
  value: student.userid,
  label: `${student.nickname} ${student.firstname} ${student.lastname}`
}));

const classOptions = classes.map(cls => ({
  value: cls.groupid,
  label: `${cls.class_code} ${t('start_date')} ${formatDate(cls.start_date)}`
}));

const inventoryOptions = inventoryItems.map(item => ({
  value: item.inventory_id,
  label: `${item.item_name} (${formatNumber(item.price_per_unit)})`,
  price_per_unit: parseFloat(item.price_per_unit), // Ensure this is a number
  vat: item.vat // Add VAT if applicable
}));


  const handleStudentSelect = (selectedOption) => {
    setSelectedStudent(selectedOption ? selectedOption.value : '');
    const selectedStudent = students.find(student => student.userid === selectedOption.value);
    setStudentDetails(selectedStudent || {});
  };

  const handleClassSelect = (selectedOption) => {
    setSelectedClass(selectedOption ? selectedOption.value : '');
    const selectedClass = classes.find(cls => cls.groupid === selectedOption.value);
    const classPrice = selectedClass ? parseFloat(selectedClass.price) || 0 : 0; // Ensure it's a number
    setSchedule(selectedClass ? selectedClass.classes : []);
    setBaseClassPrice(classPrice);
    setPrice(classPrice); // Set the price to only class price initially
    setPromotion('');
    setInventoryPrice(0); // Reset inventory price
    const initialPriceBeforeVat = classPrice; // Set initial price before VAT
    setPriceBeforeVat(initialPriceBeforeVat); // Initialize price before VAT
    calculateTotalPrice(initialPriceBeforeVat, promotion, vat); // Recalculate total price
};

const handleInventorySelect = (selectedOptions) => {
    setSelectedInventoryItems(selectedOptions);

    // Calculate the total cost of selected inventory items
    const inventoryCost = selectedOptions.reduce((total, item) => {
        return total + (parseFloat(item.price_per_unit) || 0); // Ensure item.price_per_unit is parsed to float
    }, 0);


    setInventoryPrice(inventoryCost); // Set inventory price state

    const newPriceBeforeVat = baseClassPrice + inventoryCost - (parseFloat(promotion) || 0); // Calculate new price before VAT
    setPriceBeforeVat(newPriceBeforeVat); // Update price before VAT

    calculateTotalPrice(newPriceBeforeVat, promotion, vat); // Recalculate total price
};

const handlePromotionChange = (e) => {
    const promoValue = e.target.value;
    setPromotion(promoValue);

    const parsedPromotion = parseFloat(promoValue) || 0; // Parse promotion as a float or default to 0
    const newPriceBeforeVat = baseClassPrice + inventoryPrice - parsedPromotion; // Calculate price before VAT
    setPriceBeforeVat(newPriceBeforeVat); // Update price before VAT state

    calculateTotalPrice(newPriceBeforeVat, promoValue, vat); // Call the function
};

const handleVatChange = (e) => {
    const isChecked = e.target.checked;
    setVat(isChecked);
    calculateTotalPrice(priceBeforeVat, promotion, isChecked); // Use priceBeforeVat in calculation
};

const calculateTotalPrice = (priceBeforeVat, promotion, vat) => {
    let finalPrice = priceBeforeVat || 0; // Initialize with price before VAT

    // Apply VAT
    if (vat) {
        finalPrice = finalPrice * 1.07; // Apply 7% VAT
    }

    setTotalPrice(finalPrice); // Update total price state
};


  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const clearSignature = () => {
    if (sigCanvas.current) {
      sigCanvas.current.clear();
    }
  };

  const handleConfirmPayment = async () => {
    setIsPaymentConfirmed(true);

    const validPromotion = promotion ? parseFloat(promotion) : 0;
    if (isNaN(validPromotion)) {
        alert("Invalid promotion value");
        return;
    }

    const validPrice = price ? parseFloat(price) : 0;
    if (isNaN(validPrice) || validPrice <= 0) {
        alert("Invalid price value");
        return;
    }

    const validTotalPrice = totalPrice ? parseFloat(totalPrice) : 0;
    if (isNaN(validTotalPrice) || validTotalPrice <= 0) {
        alert("Invalid total price value");
        return;
    }

    const userData = JSON.parse(localStorage.getItem('userData'));
    const userId = userData ? userData.userId : null;

    const signature = sigCanvas.current.isEmpty() ? null : sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
    
    // Preparing registration data
    const registrationData = {
        studentId: selectedStudent,
        classId: selectedClass,
        promotion: validPromotion,
        finalPrice: validTotalPrice,
        paymentMethod: paymentMethod,
        paymentConfirmed: !!signature,
        createdBy: userId,
        price: validPrice,
        discount: validPromotion,
        signature: signature,
        memo: memo,
        inventoryItems: selectedInventoryItems, // Ensure inventoryItems are included
        inventoryPrice: inventoryPrice, // Include inventory price
        vat: vat ? 0.07 : 0 // Include VAT status (if applied or not)
    };

    try {
        const token = localStorage.getItem('token');
        await axios.post(`${API_URL}/registrations`, registrationData, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        // Show confirmation modal
        setConfirmationModalOpen(true);

        // Reset state
        setSelectedStudent('');
        setStudentDetails({});
        setSelectedClass('');
        setSchedule([]);
        setPromotion('');
        setPrice(0);
        setTotalPrice(0);
        setPaymentMethod('');
        setMemo('');
        setIsPaymentConfirmed(false);
        setVat(false);
        setInventoryPrice(0);
        setSelectedInventoryItems([]); // Reset selected inventory items
        clearSignature();
        closeModal();
    } catch (error) {
        console.error('Error saving registration:', error);
    }
};

  const closeConfirmationModal = () => {
    setConfirmationModalOpen(false);
  };

  const generateSchedulePDF = (schedule) => {
    const doc = new jsPDF();
    loadSarabunFont(doc);
    doc.setFont('Sarabun', 'normal');
  
    const margin = 20;
    const startY = margin + 60; // Position after the header
  
    // Sort the schedule by date and time (earlier to later)
    const sortedSchedule = schedule.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
  
      if (dateA.getTime() === dateB.getTime()) {
        // If dates are the same, sort by schedule_time
        return a.schedule_time.localeCompare(b.schedule_time);
      }
      return dateA - dateB;
    });
  
    // Load and add the header image
    const headerImage = new Image();
    headerImage.src = '/images/Theplanner.png'; // Adjust the path as needed
  
    headerImage.onload = () => {
      // Add header image (40x40 px)
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);
  
      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);
  
      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);
  
      // Prepare class details data
      const classCode = sortedSchedule[0]?.class_code || 'N/A'; // Get class_code from sorted schedule
      const totalHours = sortedSchedule?.[0]?.total_hours || 0;
      const subjects = [...new Set(sortedSchedule.map(slot => slot.subject_name))]; // Get unique subjects
      const subjectsText = subjects.join(', ');
  
   // Add class details below the header (centered)
      doc.setFontSize(12);
      const pageWidth = doc.internal.pageSize.getWidth(); // Get the page width

      doc.text(`Course: ${classCode}`, pageWidth / 2, startY - 15, { align: 'center' });
      doc.text(`Total Hours: ${formatMinutesToHours(totalHours)}`, pageWidth / 2, startY - 10, { align: 'center' });
      doc.text(`Subject(s): ${subjectsText}`, pageWidth / 2, startY - 5, { align: 'center' });

  
      // Add the main title
      doc.setFontSize(18);
      doc.setFont('Sarabun', 'normal');
      doc.text('', 105, startY, { align: 'center' });
  
      // Create the table with class schedule details
      doc.autoTable({
        startY: startY + 10,
        head: [['Date', 'Day', 'Time', 'End Time', 'Hours', 'Subject Name']],
        body: sortedSchedule.map(slot => [
          formatDate(slot.date),
          slot.schedule_day,
          formatTime(slot.schedule_time),
          calculateEndTime(slot.schedule_time, slot.schedule_hour),
          slot.schedule_hour,
          slot.subject_name,
        ]),
        styles: { font: 'Sarabun', fontStyle: 'normal' }
      });
  
      // Save the PDF with the classCode as the file name
      doc.save(`${classCode}.pdf`);
    };
  };
  


const generateQuotationPDF = () => {
  const doc = new jsPDF();
  loadSarabunFont(doc);
  doc.setFont('Sarabun', 'normal');

  // Load and add the header image
  const headerImage = new Image();
  headerImage.src = '/images/Theplanner.png'; // Adjust the path as needed

  headerImage.onload = () => {
      // Add header image (40x40 px)
      const margin = 10;
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);

      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);

      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);

      // Add date and expiration information
      const today = moment().format('DD MMM YYYY');
      const expirationDate = moment().add(2, 'days').format('DD MMM YYYY');
      const startY = 50; // Start below the header
      doc.text(`${t('registration.date_of_quotation')}: ${today}`, margin, startY);
      doc.text(`${t('registration.quotation_expiration')}: ${expirationDate}`, margin, startY + 5);

      // Student and class details
      doc.text(`${t('registration.student_name')}: ${studentDetails.firstname} ${studentDetails.lastname}`, margin, startY + 15);
      doc.text(`${t('registration.address')}: ${studentDetails.address || 'N/A'}`, margin, startY + 20);
      doc.text(`${t('registration.phone')}: ${studentDetails.phone || 'N/A'}`, margin, startY + 25);
      
      // Main table with course and inventory details
      const bodyContent = [
          [
              '1',
              `${classes.find(cls => cls.groupid === selectedClass)?.class_code || 'N/A'}`,
              '1',
              'Course',
              formatNumber(baseClassPrice),
              formatNumber(baseClassPrice)
          ],
          ...selectedInventoryItems.map((item, index) => [
              (index + 2).toString(),
              item.label,
              '1',
              'Book',
              formatNumber(item.price_per_unit),
              formatNumber(item.price_per_unit)
          ])
      ];

      // Add summary at the end
      const totalBeforeDiscount = baseClassPrice + selectedInventoryItems.reduce((sum, item) => sum + item.price_per_unit, 0);
      const totalAfterDiscount = totalBeforeDiscount - (parseFloat(promotion) || 0);
      const vatAmount = vat ? totalAfterDiscount * 0.07 : 0; // Calculate VAT 7% only if VAT is checked
      const grandTotal = totalAfterDiscount + vatAmount; // Grand total includes VAT if applicable

      bodyContent.push(
          ['', '', '', '', 'Total', formatNumber(totalBeforeDiscount)],
          ['', '', '', '', 'Discount', formatNumber(promotion)],
          ['', '', '', '', 'Total after discount', formatNumber(totalAfterDiscount)],
          ['', '', '', '', 'VAT 7%', formatNumber(vatAmount)], // Show VAT amount calculated
          ['', '', '', '', 'Grand Total', formatNumber(grandTotal)] // Show grand total including VAT
      );

      doc.autoTable({
          startY: startY + 40,
          head: [['#', 'Description', 'Quantity', 'Unit', 'Unit Price', 'Total']],
          body: bodyContent,
          styles: { font: 'Sarabun', fontStyle: 'normal' },
          columnStyles: {
              0: { cellWidth: 10 },
              1: { cellWidth: 90 },
              2: { cellWidth: 20, halign: 'center' },
              3: { cellWidth: 20, halign: 'center' },
              4: { cellWidth: 25, halign: 'right' },
              5: { cellWidth: 25, halign: 'right' }
          }
      });

      // Add QR code image for payment at the bottom
      const qrCodeImage = 'https://pbs.twimg.com/media/DY8Qm7_U0AAzaFm.jpg';
      doc.addImage(qrCodeImage, 'JPEG', margin, doc.lastAutoTable.finalY + 10, 50, 0); // Set height to 0 to maintain aspect ratio

      // Save PDF
      doc.save('quotation.pdf');
  };
};


return (
  <div className="registration-container">
    <Sidebar />
    <Header />
    <h1>{t('registration.register_for_course')}</h1>
    <form className="registration-form">
      <div className="form-left">
        <div className="re-form-group">
          <label htmlFor="student">{t('registration.select_student')}</label>
          <Select
            options={studentOptions}
            onChange={handleStudentSelect}
            placeholder={t('registration.search_and_select_student')}
          />
        </div>
        {selectedStudent && (
          <div className="re-form-address">
            <p><strong>{t('registration.address')}:</strong> {studentDetails.address || 'N/A'}</p>
            <p><strong>{t('registration.phone')}:</strong> {studentDetails.phone || 'N/A'}</p>
          </div>
        )}
        <div className="re-form-group">
          <label htmlFor="class">{t('registration.select_class')}</label>
          <Select
            options={classOptions}
            onChange={handleClassSelect}
            placeholder={t('registration.select_class')}
          />
        </div>
        <div className="re-form-group">
          <label>{t('registration.select_inventory_items')}</label>
          <CreatableSelect
            options={inventoryOptions}
            onChange={handleInventorySelect}
            value={selectedInventoryItems}
            isMulti
            placeholder={t('registration.select_inventory')}
          />
        </div>

        {selectedClass && (
          <div className="re-form-group">
            <label>{t('registration.class_schedule')}</label>
            <div className="schedule-list">
            {schedule.map((slot, index) => (
              <div key={index} className="schedule-item">
                <span>{formatDate(slot.date)} {slot.schedule_day}: </span>
                <span>{formatTime(slot.schedule_time)} ({slot.schedule_hour}) - </span>
                <span>{slot.subject_name}</span>
              </div>
            ))}
          </div>
          </div>
        )}
        <button type="button" className="submit-button" onClick={() => generateSchedulePDF(schedule)}>
          <FontAwesomeIcon icon={faPrint} /> {t('registration.print_schedule')} (English Only)
        </button>
      </div>
      <div className="form-right">
  <div className="re-form-group">
    <label>{t('registration.class_price')}</label>
    <p className="price-display">{formatNumber(price)}</p>
  </div>
  <div className="re-form-group">
    <label>{t('registration.inventory_price')}</label>
    <p className="price-display">{formatNumber(inventoryPrice)}</p>
  </div>
  <div className="re-form-group">
    <label>{t('registration.promotion_discount')}</label>
    <input
      type="text"
      id="promotion"
      value={promotion}
      onChange={handlePromotionChange}
      placeholder={t('registration.enter_promotion_value')}
    />
  </div>
  <div className="re-form-group">
    <label>{t('registration.price_before_vat')}</label>
    <p className="price-display">{formatNumber(priceBeforeVat)}</p>
  </div>
  <div className="custom-checkbox">
    <input type="checkbox" id="vat" checked={vat} onChange={handleVatChange} />
    <label htmlFor="vat" className="checkbox-label">{t('registration.vat')}</label>
    {vat && <span className="vat-amount">{formatNumber(priceBeforeVat * 0.07)}</span>}
  </div>
  <div className="re-form-group">
    <label>{t('registration.total_price')}</label>
    <p className="price-display">{formatNumber(totalPrice)}</p>
  </div>


        <button type="button" className="quotation-button" onClick={generateQuotationPDF}>
          <FontAwesomeIcon icon={faFileInvoice} /> {t('registration.quotation')} (English Only)
        </button>
        <div className="re-form-group">
          <label htmlFor="paymentMethod">{t('registration.select_payment_method')}</label>
          <select id="paymentMethod" value={paymentMethod} onChange={(e) => setPaymentMethod(e.target.value)}>
            <option value="">{t('registration.select_payment_method')}</option>
            <option value="cash">{t('registration.cash')}</option>
            <option value="transfer">{t('registration.transfer')}</option>
            <option value="qr">{t('registration.qr_code')}</option>
            <option value="credit">{t('registration.credit_card')}</option>
          </select>
        </div>

        <div className="re-form-group">
          <label htmlFor="memo">{t('registration.memo')}</label>
          <textarea
            id="memo"
            value={memo}
            onChange={(e) => setMemo(e.target.value)}
            placeholder={t('registration.enter_additional_notes')}
          />
        </div>
        <div className="re-form-group">
          <label className="checkbox-label">{t('registration.confirm_payment')}</label>
          <SignatureCanvas
            ref={sigCanvas}
            penColor="black"
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
          />
        </div>
        <div className="re-form-group">
          <button type="button" onClick={clearSignature} className="singature-button">{t('registration.clear_signature')}</button>
        </div>

        <button type="button" className="submit-button" onClick={openModal}>
          <FontAwesomeIcon icon={faFileInvoice} /> {t('registration.confirm_registration')}
        </button>
      </div>
    </form>
    {isPaymentConfirmed && <p>{t('registration.payment_confirmed')}</p>}
    <Modal isOpen={isModalOpen} onClose={closeModal}>
      <h2>{t('registration.confirm_registration_details')}</h2>
      <div>
        <p><strong>{t('registration.student')}:</strong> {studentDetails.firstname} {studentDetails.lastname}</p>
        <p><strong>{t('registration.address')}:</strong> {studentDetails.address || 'N/A'}</p>
        <p><strong>{t('registration.phone')}:</strong> {studentDetails.phone || 'N/A'}</p>
        <p><strong>{t('registration.class')}:</strong> {classes.find(cls => cls.groupid === selectedClass) ? `${classes.find(cls => cls.groupid === selectedClass).class_code} - ${classes.find(cls => cls.groupid === selectedClass).subject_name}` : selectedClass}</p>
        <p><strong>{t('registration.price')}:</strong> {formatNumber(price)}</p>
        <p><strong>{t('registration.promotion')}:</strong> {promotion}</p>
        <p><strong>{t('registration.vat')}:</strong> {vat ? formatNumber(price * 0.07) : 'N/A'}</p>
        <p><strong>{t('registration.total_price')}:</strong> {formatNumber(totalPrice)}</p>
        <p><strong>{t('registration.payment_method')}:</strong> {paymentMethod}</p>
        <p><strong>{t('registration.memo')}:</strong> {memo}</p>
        {sigCanvas.current && !sigCanvas.current.isEmpty() && <img src={sigCanvas.current.getTrimmedCanvas().toDataURL('image/png')} alt="signature" style={{ width: '100%', maxHeight: '200px' }} />}
      </div>
      <button type="button" onClick={handleConfirmPayment}>{t('registration.confirm')}</button>
    </Modal>
    <Modal isOpen={confirmationModalOpen} onClose={closeConfirmationModal}>
      <h2>{t('registration.successful_registration')}</h2>
    </Modal>
  </div>
);
}

export default withRoleAccess(Registrations, ['superadmin', 'admin']);