import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faGraduationCap, faBirthdayCake } from '@fortawesome/free-solid-svg-icons';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { QRCodeCanvas } from 'qrcode.react';
import getUserIdFromToken from '../../Utils/authUtils';
import Modal from '../../components/Modal';
import moment from 'moment';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import './Student.css';
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


function StudentDashboard() {
  const { t, i18n } = useTranslation(); // Use translation hook
  const userId = getUserIdFromToken();
  const [student, setStudent] = useState(null);
  const [studentTestRegistrations, setStudentTestRegistrations] = useState([]);
  const [error, setError] = useState(null);
  const [studentAttendance, setStudentAttendance] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [popupData, setPopupData] = useState(null);
  const [absentCounts, setAbsentCounts] = useState({});

  // Pagination state
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const API_URL = process.env.REACT_APP_API_URL;

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };


  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };
  useEffect(() => {
    // Fetch student data
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        const studentData = response.data;
        setStudent({
          ...studentData,
          photourl: studentData.photourl || 'https://via.placeholder.com/150',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    // Fetch student attendance data
    const fetchStudentAttendance = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${userId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentAttendance(response.data);
        calculateAbsences(response.data); // Calculate absences when fetching data
      } catch (error) {
        console.error('Error fetching student attendance:', error);
      }
    };

    // Function to calculate absences
    const calculateAbsences = (attendanceData) => {
      const absences = {};
      attendanceData.forEach(attendance => {
        const statusLowerCase = attendance.status.toLowerCase();
        if (statusLowerCase === 'absent') {
          if (!absences[attendance.class_code]) {
            absences[attendance.class_code] = 1;
          } else {
            absences[attendance.class_code] += 1;
          }
        }
      });
      setAbsentCounts(absences);
    };

    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/user`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const { registrations } = response.data;
  
        // Sort schedules for each registration
        const sortedRegistrations = registrations.map(reg => {
          const sortedSchedule = reg.schedule.sort((a, b) => {
            const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm:ss');
            const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm:ss');
            return dateTimeA - dateTimeB;
          });
  
          return { ...reg, schedule: sortedSchedule };
        });
  
        // Sort registrations by the earliest class in their schedule
        sortedRegistrations.sort((a, b) => {
          const earliestA = a.schedule[0] ? moment(`${a.schedule[0].date} ${a.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          const earliestB = b.schedule[0] ? moment(`${b.schedule[0].date} ${b.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          return earliestA - earliestB;
        });
  
        setBillingHistory(sortedRegistrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    // Fetch student test registrations
    const fetchStudentTestRegistrations = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test-scores/test-registrations`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentTestRegistrations(response.data); // Store fetched test registrations in state
      } catch (error) {
        console.error('Error fetching student test registrations:', error);
      }
    };

    // Call the functions
    fetchStudentData();
    fetchStudentAttendance();
    fetchBillingHistory();
    fetchStudentTestRegistrations(); // Fetch test registrations

  }, [userId, API_URL]);



  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);

    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;

    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }

    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };

  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  }, [i18n.language]);

  useEffect(() => {
    const recentDate = new Date();
    recentDate.setDate(recentDate.getDate() - 7);
  
    // Function to get dismissed toasts from localStorage
    const getDismissedToasts = () => {
      const dismissed = localStorage.getItem('dismissedToasts');
      return dismissed ? JSON.parse(dismissed) : [];
    };
  
    // Function to save dismissed toasts to localStorage
    const saveDismissedToasts = (dismissedToasts) => {
      localStorage.setItem('dismissedToasts', JSON.stringify(dismissedToasts));
    };
  
    const dismissedToasts = getDismissedToasts();
  
    studentAttendance.forEach(attendance => {
      const attendanceDate = new Date(attendance.timestamp);
  
      // Check if the attendance is within the last 7 days and the student was absent
      if (attendanceDate >= recentDate && attendance.status.toLowerCase() === 'absent') {
        const toastId = `${attendance.class_code}_${attendance.timestamp}`;
        
        // Check if this toast has already been dismissed
        if (!dismissedToasts.includes(toastId)) {
          const count = absentCounts[attendance.class_code] || 1;
  
          toast.info(
            `You were marked absent for class ${attendance.class_code} on ${formatDate(attendance.timestamp)}. This is your ${count}${getOrdinalSuffix(count)} absence for this class.`,
            {
              position: "top-right",
              autoClose: true,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: 'absent-toast',
              style: {
                backgroundColor: '#f8d7da',
                color: '#721c24',
                border: '1px solid #f5c6cb',
              },
              // When the toast is closed, add it to the dismissed toasts
              onClose: () => {
                const updatedDismissedToasts = [...dismissedToasts, toastId];
                saveDismissedToasts(updatedDismissedToasts);
              },
            }
          );
        }
      }
    });
  }, [studentAttendance, absentCounts, formatDate]);
  
  
   // student's upcoming tests
   useEffect(() => {
    const today = new Date();
  
    studentTestRegistrations.forEach(test => {
      const testDate = new Date(test.test_date);
      const daysBeforeTest = Math.ceil((testDate - today) / (1000 * 60 * 60 * 24));
  
      if (daysBeforeTest === 1) {
        toast.info(
          `Reminder: Your ${test.test_type} (${test.sub_type}) test is scheduled on ${formatDate(test.test_date)}.`,
          {
            position: "top-right",
            autoClose: true,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            style: {
              backgroundColor: '#f8d7da', // Light red background
              border: '1px solid red', // Red border
              color: 'black',
            },
            icon: '⚠️',
          }
        );
      }
    });
  }, [studentTestRegistrations, formatDate]);
  
  
   // Function to get the ordinal suffix for numbers (1st, 2nd, 3rd, etc.)
   const getOrdinalSuffix = (i) => {
    const j = i % 10,
      k = i % 100;
    if (j === 1 && k !== 11) {
      return "st";
    }
    if (j === 2 && k !== 12) {
      return "nd";
    }
    if (j === 3 && k !== 13) {
      return "rd";
    }
    return "th";
  };

  
  const openSchedulePopup = (data) => {
    setPopupData(data);
  };
  

  const closePopup = () => {
    setPopupData(null);
  };

  const sortByDateAscending = (schedule) => {
    return schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  const filterFutureClasses = (schedule) => {
    // Return the schedule without filtering for future classes
    return schedule;
  };

  const filteredAndSortedBillingHistory = useMemo(() => {
    return billingHistory.map(enrollment => ({
      ...enrollment,
      schedule: sortByDateAscending(filterFutureClasses(enrollment.schedule))
    })).filter(enrollment => enrollment.schedule.length > 0);
  }, [billingHistory]);

  const sortedAndFilteredSchedule = useMemo(() => {
    return filteredAndSortedBillingHistory
      .flatMap(enrollment => 
        enrollment.schedule.map(schedule => ({ ...schedule, class_code: enrollment.class_code }))
      )
      .sort((a, b) => moment(a.date + ' ' + a.time, 'YYYY-MM-DD HH:mm:ss') - moment(b.date + ' ' + b.time, 'YYYY-MM-DD HH:mm:ss'));
  }, [filteredAndSortedBillingHistory]);

  const paginatedSchedule = useMemo(() => {
    return sortedAndFilteredSchedule.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [sortedAndFilteredSchedule, currentPage, itemsPerPage]);

  // Calculate total pages
  const totalPages = Math.ceil(sortedAndFilteredSchedule.length / itemsPerPage);


  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const eventContent = (eventInfo) => {
    const startTime = eventInfo.event.startStr.split('T')[1].slice(0, 5);
    const endTime = eventInfo.event.endStr.split('T')[1].slice(0, 5);
    const { subject, teacher, classroom } = eventInfo.event.extendedProps; // Extract classroom
  
    return (
      <div style={{ 
        backgroundColor: eventInfo.event.backgroundColor, 
        color: eventInfo.event.textColor, 
        padding: '5px', 
        borderRadius: '3px',
        fontSize: '0.8em', // Slightly reduce font size to fit more content
        lineHeight: '1.2' // Tighten line height for compactness
      }}>
        <div style={{ fontWeight: 'bold' }}>{`${startTime} - ${endTime}`}</div>
        <div>{eventInfo.event.title}</div>
        <div>{subject}</div>
        <div>{teacher}</div>
        <div>{classroom && `Room: ${classroom}`}</div> {/* Add classroom here */}
      </div>
    );
};


  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;

  return (
    <div>
            <div className="student-dashboard-container">
            <ToastContainer />
      <Sidebar />
      <Header />
      <div>
      <div className="student-dashboard-content">
      <div className="student-profile">
      <h2>Student Card</h2>
      <div className="student-id-card">
          <img src='/images/cap.png' alt="" className="student-logo" />
          <img src={student.photourl} alt={`${student.firstname} ${student.lastname}`} />
          <div className="student-name">{student.firstname} {student.lastname}</div>
          <div className="student-year">{student.schoolid}</div>
          <QRCodeCanvas value={student.schoolid} size={100} className="student-qr" />
      </div>

      </div>

        <div className="today-classes">
        <h2>{t('profile')}</h2>
        <p><FontAwesomeIcon icon={faEnvelope} className="icon" /><strong>{t('email')}:</strong> {student.email}</p>
            <p><FontAwesomeIcon icon={faPhone} className="icon" /><strong>{t('phone')}:</strong> {student.phone}</p>
            <p><FontAwesomeIcon icon={faHome} className="icon" /><strong>{t('address')}:</strong> {student.address}</p>
            <p><FontAwesomeIcon icon={faBirthdayCake} className="icon" /><strong>{t('dob')}:</strong> {formatDate(student.date_of_birth)}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('currentEducationLevel')}:</strong> {student.currenteducationlevel}</p>

      </div>

      <div className="student-attendance">
        <h2>{t('myupcomingtest')}</h2>
        <div className="billing-history-cards">
          {studentTestRegistrations.length > 0 ? (
            studentTestRegistrations.map((test, index) => (
              <div key={index} className="billing-card">
                <div className="billing-card-content">
                  <div className="billing-card-header">
                    {test.test_type} - {test.sub_type}
                  </div>
                  <div className="billing-card-hours">
                    {t('Test Date')}: {new Date(test.test_date).toLocaleDateString()}
                  </div>
                  <div className={`billing-card-status ${test.paid_status === 'paid' ? 'paid-status' : 'unpaid-status'}`}>
                    {t('Status')}: {test.paid_status === 'paid' ? t('Paid') : t('Unpaid')}
                  </div>
                </div>
              </div>
            ))
          ) : (
            <p>{t('No upcoming tests found.')}</p>
          )}
        </div>
      </div>

        
      </div>
      <div className="student-list-box-container">
        <div className="student-list-box">
           <h2>{t('classSchedule')}</h2>
           <table className="classtable">
            <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('classCode')}</th>
                <th>{t('classid')}</th>
                <th>{t('subject')}</th>
                <th>{t('teacher')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
                <th>{t('attendance')}</th>
              </tr>
            </thead>
            <tbody>
            {paginatedSchedule.map((cls, idx) => {
              const attendance = studentAttendance.find(att => att.classid === cls.classid);
              const attendanceStatus = attendance ? attendance.status : '';
              return (
                <tr key={idx}>
                  <td>{formatDate(cls.date)}</td>
                  <td>{cls.class_code}</td>
                  <td>{cls.classid}</td>
                  <td>{cls.subject}</td>
                  <td>{cls.teacher}</td>
                  <td>{formatTime(cls.time)} - {calculateEndTime(cls.time, cls.hours)}</td>
                  <td>{cls.hours} {t('hr')}</td>
              <td>
                {/* Conditionally render attendance status with background colors */}
                {attendanceStatus === 'Present' && (
                  <span style={{
                    color: 'white',
                    backgroundColor: '#3498db',
                    padding: '5px 10px',
                    borderRadius: '4px',
                  }}>
                    {attendanceStatus}
                  </span>
                )}
                {attendanceStatus === 'Absent' && (
                  <span style={{
                    color: 'white',
                    backgroundColor: '#e74c3c',
                    padding: '5px 10px',
                    borderRadius: '4px',
                  }}>
                    {attendanceStatus}
                  </span>
                )}
                {attendanceStatus === 'Late' && (
                  <span style={{
                    color: 'white',
                    backgroundColor: '#f39c12',
                    padding: '5px 10px',
                    borderRadius: '4px',
                  }}>
                    {attendanceStatus}
                  </span>
                )}
                {!['Present', 'Absent', 'Late'].includes(attendanceStatus) && (
                  <span>{attendanceStatus}</span>
                )}
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
          {paginatedSchedule.map((cls, index) => (
            <div key={index} className="class-card">
              <div className="class-card-date">{formatDate(cls.date)}</div>
              <div className="class-card-details">
                <div className="class-card-item">{cls.class_code}</div>
                <div className="class-card-item"> {cls.subject}</div>
                <div className="class-card-item"><strong>{t('teacher')}:</strong> {cls.teacher}</div>
                <div className="class-card-item"><strong>{t('time')}:</strong> {formatTime(cls.time)}</div>
                <div className="class-card-item"><strong>{t('hours')}:</strong> {cls.hours}</div>
              </div>
            </div>
          ))}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button key={index} onClick={() => handlePageChange(index + 1)} disabled={currentPage === index + 1}>
              {index + 1}
            </button>
          ))}
        </div>
      </div>
        <div className="student-list-box">
        <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin]}
            initialView="dayGridMonth"
            locale={i18n.language === 'th' ? thLocale : 'en'}
            events={filteredAndSortedBillingHistory.flatMap((enrollment) =>
              enrollment.schedule.map((cls) => {
                const formattedStartTime = cls.time.slice(0, 5);
                const startDateTime = `${cls.date.split('T')[0]}T${formattedStartTime}:00`;
                const formattedEndTime = calculateEndTime(formattedStartTime, cls.hours);
                const endDateTime = `${cls.date.split('T')[0]}T${formattedEndTime}:00`;
                
                return {
                  title: `${enrollment.class_code}`,
                  start: startDateTime,
                  end: endDateTime,
                  color: '#005181', 
                  textColor: '#ffffff',
                  extendedProps: {
                    teachers: enrollment.teachers.map((teacher) => teacher.teacher_name).join(', '),
                    time: formattedStartTime,
                    hours: cls.hours,
                    subject: cls.subject,
                    teacher: cls.teacher,
                    classroom: cls.classroom
                  }
                };
              })
            )}
            eventContent={eventContent}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek'
            }}
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false
            }}
            height="auto"
            slotMinTime="06:00:00"
            slotMaxTime="21:00:00"
          />
        </div>
      </div>

      <div className="all-classes">
        <h2>{t('currentEnrollments')}</h2>
        <div className="student-card-container">
          {billingHistory.map((enrollment, index) => (
            <div key={index} className="student-card">
              <div className="student-card-header">
                <Link to={`/classdetailsstudent/${enrollment.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                  <button className="student-class-code-button">{enrollment.class_code}</button>
                </Link>
              </div>
              <div className="student-card-body">
                <div className="student-card-subjects">
                  {enrollment.subjects.map((subject, idx) => (
                    <div key={idx}>{subject.title}</div>
                  ))}
                </div>
                <div className="student-card-schedule">
                  <button onClick={() => openSchedulePopup(sortByDateAscending(enrollment.schedule))}>{t('viewSchedule')}</button>
                </div>
                <div className="student-card-hours">
                  <strong>{t('totalHours')}:</strong> {enrollment.total_hours}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {popupData && (
        <Modal isOpen={!!popupData} onClose={closePopup}>
          <h2>{t('scheduleDetails')}</h2>
          <table className="schedule-table">
            <thead>
              <tr>
                <th>{t('subjectName')}</th>
                <th>{t('teacherName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {popupData.map((slot, index) => (
                <tr key={index}>
                  <td>{slot.subject}</td>
                  <td>{slot.teacher}</td>
                  <td>{getDayOfWeek(slot.date)}</td>
                  <td>{formatDate(slot.date)}</td>
                  <td>{formatTime(slot.time)} - {calculateEndTime(slot.time, slot.hours)}</td>
                  <td>{slot.hours} {t('hr')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
      </div>
    </div>

  );
}

export default withRoleAccess(StudentDashboard, ['student']);
