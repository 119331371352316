import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import withRoleAccess from '../../hoc/withRoleAccess';

const ClassChange = () => {
    const { i18n, t } = useTranslation(); 
    const [classChangeLogs, setClassChangeLogs] = useState([]);
    const API_URL = process.env.REACT_APP_API_URL;

    const formatTime = (timeString) => {
        try {
            const [hours, minutes] = timeString.split(':');
            return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
        } catch (error) {
            console.error('Invalid time value:', timeString);
            return t('invalidTime');
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    };

    const formatDateTime = (dateTimeString) => {
        const dateTime = new Date(dateTimeString);
        const options = { day: '2-digit', month: 'short', year: 'numeric', hour: '2-digit', minute: '2-digit' };
        return new Intl.DateTimeFormat(i18n.language, options).format(dateTime).toUpperCase();
    };

    const calculateDuration = (startTime, endTime) => {
        try {
            const start = new Date(`1970-01-01T${startTime}Z`);
            const end = new Date(`1970-01-01T${endTime}Z`);
            const diff = (end - start) / (1000 * 60); // difference in minutes

            const hours = Math.floor(diff / 60);
            const minutes = diff % 60;

            return `${hours}:${minutes.toString().padStart(2, '0')} ${t('hr')}`;
        } catch (error) {
            console.error('Error calculating duration:', error);
            return t('invalidDuration');
        }
    };

    const getChangeReasonDisplay = (reason) => {
        switch (reason) {
            case 'admin_adjust':
                return { text: 'Admin', color: '#005181' };
            case 'cancelled_by_student':
                return { text: 'CL by S.', color: 'red' };
            case 'cancelled_by_teacher':
                return { text: 'CL by T.', color: 'orange' };
            case 'class_on_hold_by_student':
                return { text: 'On hold by S.', color: 'blue' };
            case 'class_on_hold_by_school':
                return { text: 'On hold by P.', color: 'green' };
            case 'others':
                return { text: 'Others', color: 'darkgrey' };
            default:
                return { text: reason, color: 'black' };
        }
    };

    const fetchClassChangeLogs = useCallback(async () => {
        try {
            const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
                headers: {
                    Authorization: `Bearer ${localStorage.getItem('token')}`
                }
            });
            setClassChangeLogs(response.data);
        } catch (error) {
            console.error('Error fetching class change logs:', error);
        }
    }, [API_URL]);

    useEffect(() => {
        fetchClassChangeLogs();
    }, [fetchClassChangeLogs]);

    return (
        <div>
            <h1>Class Change Logs</h1>
            <table>
                <thead>
                    <tr>
                        <th>{t('classcode')}</th>
                        <th>{t('id')}</th>
                        <th>{t('subject')}</th>
                        <th>{t('teacher')}</th>
                        <th>{t('originaldate')}</th>
                        <th>{t('originaltime')}</th>
                        <th>{t('originalhour')}</th>
                        <th>{t('newdate')}</th>
                        <th>{t('newtime')}</th>
                        <th>{t('newhour')}</th>
                        <th>{t('changereason')}</th>
                        <th>{t('note')}</th>
                        <th>{t('admin')}</th>
                        <th>{t('updateat')}</th>
                    </tr>
                </thead>
                <tbody>
                    {classChangeLogs.map(log => {
                        const { text: reasonText, color: reasonColor } = getChangeReasonDisplay(log.changereason);
                        return (
                            <tr key={log.id}>
                                <td>{log.class_code}</td>
                                <td>{log.classid}</td>
                                <td>{log.subject_name}</td>
                                <td>{log.nickname}</td>
                                <td>{formatDate(log.originaldate)}</td>
                                <td>{formatTime(log.originalstarttime)} - {formatTime(log.originalendtime)}</td>
                                <td>{calculateDuration(log.originalstarttime, log.originalendtime)}</td>
                                <td>{formatDate(log.newdate)}</td>
                                <td>{formatTime(log.newstarttime)} - {formatTime(log.newendtime)}</td>
                                <td>{calculateDuration(log.newstarttime, log.newendtime)}</td>
                                <td style={{ color: reasonColor }}>{reasonText}</td> {/* Apply style */}
                                <td>{log.note}</td>
                                <td>{log.updateby}</td>
                                <td>{formatDateTime(log.updated_at)}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};

export default withRoleAccess(ClassChange, ['admin', 'superadmin']);
