import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import { sarabunFont } from '../../sarabunFont';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faCalendarAlt, faChalkboardTeacher, faBook, faVideo, faUserClock, faCalendar, faUser, faDoorOpen, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import StarRating from './StarRating';
import './Classes.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 



const ClassDetailsStudent = () => {
  const { t } = useTranslation();
  const { groupid } = useParams();
  const [classDetails, setClassDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [attendanceStatistics, setAttendanceStatistics] = useState({});
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [classComments, setClassComments] = useState({});
  const [registeredStudents, setRegisteredStudents] = useState([]);
  const [videoMeetingLinks, setVideoMeetingLinks] = useState({});
  const [ratings, setRatings] = useState({});
  const [comments, setComments] = useState({});
  const [submittedRatings, setSubmittedRatings] = useState({});
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [uploadedPdfs, setUploadedPdfs] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const timetableRef = useRef(); 

  const API_URL = process.env.REACT_APP_API_URL;

  const studentId = localStorage.getItem('userId'); 


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat('en-GB', options).format(date).toUpperCase();
  };
  
  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(':');
    return `${hour}:${minute}`;
  };
  
  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = (minutes % 60).toString().padStart(2, '0'); // Pad with zero if necessary
    const hrLabel = t('hr'); // Call the translation function
  
    return `${hours}:${remainingMinutes} ${hrLabel}`;
  };
  
  const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    return `${hours}:${minutes}`;
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

  const calculateEndTime = (startTime, durationMinutes) => {
    return moment(startTime, 'HH:mm:ss')
      .add(durationMinutes, 'minutes')
      .format('HH:mm');
  };

  const formatHours = (decimalHours) => {
    const hours = Math.floor(decimalHours);
    const minutes = Math.round((decimalHours - hours) * 60);
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`;
  };
  

  const formatScheduleHour = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}:${remainingMinutes.toString().padStart(2, '0')} ${t('hrs')}`;
};


  useEffect(() => {
    const fetchClassDetails = async () => {
      try {
        const response = await axios.get(`${API_URL}/class-groups/${groupid}`);
        setClassDetails(response.data);

        const { classgroup_ids, classes } = response.data;

        const fetchMessages = async () => {
          const token = localStorage.getItem('token');
          const messageResponses = await Promise.all(classgroup_ids.map(id => 
            axios.get(`${API_URL}/messages/${id}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const messages = messageResponses.flatMap(res => res.data);
          setMessages(messages);
        };

        const fetchAllClassComments = async () => {
          const token = localStorage.getItem('token');
          const commentResponses = await Promise.all(classes.map(cls =>
            axios.get(`${API_URL}/class-comments/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const commentsData = commentResponses.flatMap(res => res.data);
          const formattedComments = commentsData.reduce((acc, comment) => {
            acc[comment.classid] = comment.comment;
            return acc;
          }, {});
          setClassComments(formattedComments);
        };

        const fetchAllVideoMeetingLinks = async () => {
          const token = localStorage.getItem('token');
          const videoLinkResponses = await Promise.all(classes.map(cls =>
            axios.get(`${API_URL}/video-meeting-links/${cls.classid}`, {
              headers: { Authorization: `Bearer ${token}` },
            })
          ));
          const videoLinksData = videoLinkResponses.flatMap(res => res.data);
          const formattedVideoLinks = videoLinksData.reduce((acc, link) => {
            acc[link.classid] = link.video_meeting_link;
            return acc;
          }, {});
          setVideoMeetingLinks(formattedVideoLinks);
        };

        const fetchAttendanceStatistics = async () => {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${API_URL}/attendance/group/${groupid}`, {
            headers: { Authorization: `Bearer ${token}` },
          });
          setAttendanceStatistics(response.data);
        };

        const fetchSubmittedRatings = async () => {
          try {
            const token = localStorage.getItem('token');
            const userId = localStorage.getItem('userId');
            
            const ratingResponses = await Promise.all(classes.map(async cls => {
              try {
                const response = await axios.get(`${API_URL}/ratings/${userId}/${cls.classid}`, {
                  headers: { Authorization: `Bearer ${token}` },
                });
                return { classId: cls.classid, data: response.data };
              } catch (error) {
                if (error.response && error.response.status === 404) {
                  return { classId: cls.classid, data: null };
                }
                throw error;
              }
            }));
            
            const formattedRatings = ratingResponses.reduce((acc, res) => {
              if (res.data) {
                acc[res.classId] = res.data;
              }
              return acc;
            }, {});
        
            setSubmittedRatings(formattedRatings);
          } catch (error) {
            console.error('Error fetching submitted ratings:', error);
          }
        };

        const fetchClassPdfs = async () => {
          try {
              const token = localStorage.getItem('token');
              const pdfResponses = await Promise.all(classes.map(cls =>
                  axios.get(`${API_URL}/upload/class/${cls.classid}`, {
                      headers: { Authorization: `Bearer ${token}` }
                  })
              ));
              const pdfData = pdfResponses.flatMap(res => res.data);
              const formattedPdfs = pdfData.reduce((acc, pdf) => {
                  if (!acc[pdf.classid]) {
                      acc[pdf.classid] = [];
                  }
                  acc[pdf.classid].push(pdf);
                  return acc;
              }, {});
              setUploadedPdfs(formattedPdfs);
          } catch (error) {
              console.error('Error fetching PDFs for classes:', error);
          }
      };

        fetchMessages();
        fetchAllClassComments();
        fetchAttendanceStatistics();
        fetchAllVideoMeetingLinks();
        fetchSubmittedRatings();
        fetchClassPdfs();

      } catch (error) {
        setError('Failed to fetch class details.');
      } finally {
        setLoading(false);
      }
    };

    const fetchRegisteredStudents = async () => {
      try {
        const response = await axios.get(`${API_URL}/registrations/classgroup/${groupid}`);
        setRegisteredStudents(response.data);
      } catch (error) {
        console.error('Error fetching registered students:', error);
      }
    };

    fetchClassDetails();
    fetchRegisteredStudents();
  }, [groupid, API_URL]);

  const handleSendMessage = async () => {
    try {
      const token = localStorage.getItem('token');
      await axios.post(
        `${API_URL}/messages`,
        { groupid, message: newMessage },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setNewMessage('');
      setMessages(prevMessages => [
        ...prevMessages,
        { firstname: 'You', lastname: '', message: newMessage, timestamp: new Date().toISOString() }
      ]);
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  const handleRatingChange = (classid, rating) => {
    setRatings(prevRatings => ({
      ...prevRatings,
      [classid]: rating
    }));
  };

  const handleCommentChange = (classid, comment) => {
    setComments(prevComments => ({
      ...prevComments,
      [classid]: comment
    }));
  };

  const handleRatingSubmit = async (classid, teacherId) => {
    try {
      const token = localStorage.getItem('token');
      const userId = localStorage.getItem('userId'); 
      const rating = ratings[classid];
      const studentcomment = comments[classid]; // Use studentcomment instead of comment

  
      await axios.post(
        `${API_URL}/ratings`,
        { classid, teacherId, studentId: userId, rating, studentcomment }, // Send studentcomment to backend
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
  
      confirmAlert({
        title: 'Success',
        message: 'Rating and student comment submitted successfully',
        buttons: [
          {
            label: 'OK',
            onClick: () => {}
          }
        ]
      });
  
      setSubmittedRatings(prevSubmittedRatings => ({
        ...prevSubmittedRatings,
        [classid]: { rating, studentcomment } 
      }));
    } catch (error) {
      console.error('Error submitting rating:', error);
      confirmAlert({
        title: 'Error',
        message: 'Failed to submit rating and student comment',
        buttons: [
          {
            label: 'OK',
            onClick: () => {}
          }
        ]
      });
    }
  };
  
  const handlePdfUpload = async (classid) => {
    if (!selectedPdf) return;

    const formData = new FormData();
    formData.append('pdf', selectedPdf);  
    formData.append('studentId', studentId); 
    formData.append('classid', classid);

    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/upload/studentupload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${token}`,
        },
      });

      // Handle the successful upload
      setUploadedPdfs((prevPdfs) => ({
        ...prevPdfs,
        [classid]: [...(prevPdfs[classid] || []), response.data],
      }));

      // Clear the selected file
      setSelectedPdf(null);

      // Show success confirmation alert
      confirmAlert({
        title: 'Success',
        message: 'PDF uploaded successfully!',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });

    } catch (error) {
      console.error('Error uploading PDF:', error);  // Log the error to see more details

      // Show error alert
      confirmAlert({
        title: 'Error',
        message: 'Failed to upload PDF. Please try again.',
        buttons: [
          {
            label: 'OK',
            onClick: () => {},
          },
        ],
      });
    }
  };

  const downloadPDF = () => {
    const doc = new jsPDF();
    loadSarabunFont(doc); // Load the Sarabun font
  
    const margin = 20;
    const startY = margin + 60; // Position after the header
  
    // Load and add the header image
    const headerImage = new Image();
    headerImage.src = '/images/Theplanner.png'; // Adjust the path as needed
  
    headerImage.onload = () => {
      // Add header image (40x40 px)
      doc.addImage(headerImage, 'PNG', margin, margin, 35, 30);
  
      // Add header text
      doc.setFontSize(10);
      doc.setFont('helvetica', 'bold');
      doc.text('THE PLANNER EDUCATION', margin + 50, margin + 10);
  
      doc.setFont('helvetica', 'normal');
      doc.text('The Mercury Ville @Chidlom 3rd floor', margin + 50, margin + 15);
      doc.text('Lumpini Pathumwan Bangkok', margin + 50, margin + 20);
      doc.text('www.theplannereducation.com', margin + 50, margin + 25);
      doc.text('Tel. 02-253-2533 or 095-726-2666 Line: @theplanner', margin + 50, margin + 30);
  
      // Prepare class details data
      const classCode = classDetails.class_code || 'N/A'; // Use actual class_code value
      const totalHoursInHours = (classDetails.total_hours / 60).toFixed(2); // Convert minutes to hours
      const subjects = classDetails.subjects || []; // Use actual subjects array
      const subjectsText = subjects.join(', ');
  
      // Calculate center of the page
      const pageWidth = doc.internal.pageSize.getWidth();
      const centerX = pageWidth / 2;
  
      // Add class details text centered on the page
      doc.setFontSize(12);
      doc.setFont('helvetica', 'normal');
      doc.text(`${classCode} (${totalHoursInHours} Hrs)`, centerX, startY - 20, { align: 'center' });
      doc.text(`Subjects: ${subjectsText}`, centerX, startY - 15, { align: 'center' });
  
      // Sort the classDetails.classes array by date and time
      const sortedClasses = classDetails.classes.sort((a, b) => {
        const dateA = new Date(a.date);
        const dateB = new Date(b.date);
  
        if (dateA.getTime() === dateB.getTime()) {
          // If dates are the same, sort by schedule_time
          return a.schedule_time.localeCompare(b.schedule_time);
        }
        return dateA - dateB;
      });
  
      // Prepare table data after sorting
      const columns = [
        { header: 'SUBJECT', dataKey: 'subject_name' },
        { header: 'TUTOR', dataKey: 'nickname' },
        { header: 'DAY', dataKey: 'schedule_day' },
        { header: 'DATE', dataKey: 'date' },
        { header: 'TIME', dataKey: 'schedule_time' },
        { header: 'HOURS', dataKey: 'schedule_hour' },
        { header: 'ROOM', dataKey: 'classroom_number' }
      ];
  
      const rows = sortedClasses.map(slot => ({
        subject_name: slot.subject_name,
        teacher_name: slot.nickname,
        schedule_day: getDayOfWeek(slot.date),
        date: formatDate(slot.date),
        schedule_time: formatTime(slot.schedule_time),
        schedule_hour: formatScheduleHour(slot.schedule_hour),
        classroom_number: slot.classroom_number
      }));
  
      // Add table below the header and class details with a custom header color
      doc.autoTable({
        startY: startY + 5, 
        head: [columns.map(col => col.header)],
        body: rows.map(row => columns.map(col => row[col.dataKey])),
        theme: 'grid',
        styles: {
          font: 'Sarabun',
          fontStyle: 'normal'
        },
        headStyles: {
          fillColor: '#005082', // Set the header background color
          textColor: '#FFFFFF' // Set the text color for the header
        }
      });
  
      // Save the PDF
      doc.save(`${classCode}.pdf`); 
    };
  
  
    headerImage.onerror = () => {
      console.error('Failed to load the header image.');
    };
  };
  
  const loadSarabunFont = (doc) => {
    doc.addFileToVFS('Sarabun.ttf', sarabunFont.src.split('base64,')[1]);
    doc.addFont('Sarabun.ttf', 'Sarabun', 'normal');
    doc.setFont('Sarabun');
  };

  const isToday = (dateString) => {
    const today = moment().startOf('day'); // Today's date without time
    const classDate = moment(dateString).startOf('day'); // Class date without time
    return today.isSame(classDate);
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;
  if (!classDetails) return <p>No class details found.</p>;

  const attendanceData = [
    { name: 'Present', value: attendanceStatistics.present, color: '#36A2EB' },
    { name: 'Late', value: attendanceStatistics.late, color: '#FFCE56' },
    { name: 'Absent', value: attendanceStatistics.absent, color: '#FF6384' },
  ];

  return (
    <div>
      <Sidebar />
      <Header />
      <div className="class-details-container">
      <div className="class-details-header">
          <h1>{classDetails.class_code}</h1>
          <h2><FontAwesomeIcon icon={faBook} className="icon"/>{classDetails.subjects?.join(', ')}</h2>
        </div>

        <div className="info-attendance-row">
          <div className="class-info-container">
            <h3 className="center-text">{t('classDetails')}</h3>
            <p><strong><FontAwesomeIcon icon={faBook} className="icon"/>{t('subject')}:</strong> {classDetails.subjects?.join(', ')}</p>
            <p><strong><FontAwesomeIcon icon={faCalendar} className="icon"/>{t('startDate')}:</strong> {formatDate(classDetails.start_date)}</p>
            <p><strong><FontAwesomeIcon icon={faClock} className="icon"/>{t('totalHours')}:</strong> {formatMinutesToHours(classDetails.total_hours)}</p>
            <p><strong><FontAwesomeIcon icon={faBook} className="icon"/>{t('bookDetails')}:</strong> {classDetails.book_details_materials}</p>
            <p><strong><FontAwesomeIcon icon={faUser} className="icon"/>{t('type')}:</strong> {classDetails.isprivate ? t('private') : t('group')}</p>
            <button onClick={() => setIsModalOpen(true)}>{t('scheduleDetails')}</button>
          </div>

          <div className="class-students-container">
            <h3 className="center-text">{t('registeredStudents')}</h3>
            <table className="transparent-table">
            <tbody>
              {registeredStudents.map((student) => (
                <tr key={student.student_id}>
                  <td>{student.student_name} ({student.nickname})</td>
                </tr>
              ))}
            </tbody>
          </table>
          </div>
        </div>

        <div className="info-attendance-row">
          <div className="class-messages-container">
            <h3 className="center-text">{t('messages')}</h3>
            <textarea
              value={newMessage}
              onChange={(e) => setNewMessage(e.target.value)}
              placeholder={t('writeMessage')}
            />
            <button onClick={handleSendMessage}>{t('sendMessage')}</button>
            <div className="message-list">
              {messages.map((msg, index) => (
                <div key={index} className="message-item">
                  <p><strong>({msg.firstname} {msg.lastname}):</strong> {msg.message}</p>
                  <small className="message-time">{formatDate(msg.timestamp)} {t('at')} {formatDateTime(msg.timestamp)}</small>
                </div>
              ))}
            </div>
          </div>
          <div className="attendance-container">
            <h3>{t('classPerformance')}</h3>
            <div className="attendance-stats">
              <p>{t('present')}: {attendanceStatistics.presentPercentage}%</p>
              <p>{t('absent')}: {attendanceStatistics.absentPercentage}%</p>
              <p>{t('late')}: {attendanceStatistics.latePercentage}%</p>
            </div>
            <div className="attendance-chart" style={{ width: '100%', height: 300 }}>
              <ResponsiveContainer>
                <PieChart>
                  <Pie
                    data={attendanceData}
                    dataKey="value"
                    nameKey="name"
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={80}
                    fill="#8884d8"
                    paddingAngle={5}
                  >
                    {attendanceData.map((entry, index) => (
                      <Cell key={`cell-${index}`} fill={entry.color} />
                    ))}
                  </Pie>
                  <Tooltip />
                  <Legend />
                </PieChart>
              </ResponsiveContainer>
            </div>
          </div>
        </div>

        <div className="class-schedule-container">
          {classDetails.classes?.map((slot, index) => (
            <div key={index} className="class-schedule-card">
              <h3>{t('class')} {index + 1} ({slot.classid})</h3>
              <h3>{slot.subject_name}</h3>
              <h4><FontAwesomeIcon icon={faChalkboardTeacher} /> {slot.nickname}</h4>
              <div className="video-meeting-link">
                {videoMeetingLinks[slot.classid] ? (
                  <div>
                    <a href={videoMeetingLinks[slot.classid]} target="_blank" rel="noopener noreferrer">
                      <FontAwesomeIcon icon={faVideo} /> {t('joinVideoMeeting')}
                    </a>
                  </div>
                ) : (
                  <p>{t('noVideoMeetingLink')}</p>
                )}
              </div>
              <p><FontAwesomeIcon icon={faCalendarAlt} /> {formatDate(slot.date)}</p>
              <p><FontAwesomeIcon icon={faClock} /> {formatTime(slot.schedule_time)}</p>
              <p><FontAwesomeIcon icon={faUserClock} /> {formatMinutesToHours(slot.schedule_hour)}</p>
              <p><FontAwesomeIcon icon={faDoorOpen} /> {slot.classroom_number}</p>
              <div className="class-comment">
                <textarea
                  placeholder={t('classComment')}
                  value={classComments[slot.classid] || ''}
                  readOnly
                  style={{ resize: 'none' }}
                />
              </div>
              <div className="pdf-uploaded-files">
              {uploadedPdfs[slot.classid]?.length > 0 ? (
      <table>
        <thead>
          <tr>
            <th>{t('Homework')}</th>
            <th>{t('Download')}</th>
          </tr>
        </thead>
        <tbody>
          {uploadedPdfs[slot.classid].map((pdf, pdfIndex) => (
            <tr key={pdfIndex}>
              <td>{pdf.originalname}</td>
              <td>
                <a
                  href={`${API_URL}/upload/download/${pdf.filename}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFilePdf} /> {t('download')}
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    ) : (
      <p>{t('noHomeworkUploaded')}</p>
    )}
        </div>
              <div className="pdf-upload">
                <input
                    type="file"
                    accept=".pdf"
                    onChange={(e) => setSelectedPdf(e.target.files[0])}
                />
                <button
                    onClick={() => handlePdfUpload(slot.classid)}
                    disabled={!selectedPdf}
                >
                    {t('Upload Your Homework')}
                </button>
                
            </div>

            {isToday(slot.date) && !submittedRatings[slot.classid] && (
              <>
                <StarRating
                  rating={ratings[slot.classid] || 0}
                  onRatingChange={(rating) => handleRatingChange(slot.classid, rating)}
                  size="2x" // Update size to a valid value
                />
                <textarea
                  placeholder={t('writeComment')}
                  value={comments[slot.classid] || ''}
                  onChange={(e) => handleCommentChange(slot.classid, e.target.value)}
                />
                <button onClick={() => handleRatingSubmit(slot.classid, slot.teacher_id)}>
                  {t('submitRating')}
                </button>
              </>
            )}

            {submittedRatings[slot.classid] && (
              <div className="submitted-feedback">
                <p><strong>{t('thankyouforyourrating')}</strong></p>
                <p><strong>{t('thankyouforyourcomment')}:</strong> {submittedRatings[slot.classid].studentcomment}</p>
              </div>
            )}


            </div>
          ))}
        </div>
      </div>
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div ref={timetableRef}>
        <h2>{classDetails?.class_code || t('scheduleDetails')}</h2> 
          <button onClick={downloadPDF}>{t('downloadPDF')}</button>
            <table>
              <thead>
                <tr>
                <th>{t('subjectName')}</th>
                <th>{t('teacherName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
                </tr>
              </thead>
              <tbody>
              {classDetails.classes
                .sort((a, b) => {
                  // First, compare by date
                  const dateA = new Date(a.date);
                  const dateB = new Date(b.date);
                  
                  if (dateA.getTime() !== dateB.getTime()) {
                    // If the dates are different, sort by date
                    return dateA - dateB;
                  } else {
                    // If the dates are the same, sort by schedule_time (assuming it's in 'HH:mm' format)
                    const timeA = a.schedule_time ? a.schedule_time.split(':').map(Number) : [0, 0]; // Default to 00:00 if time is missing
                    const timeB = b.schedule_time ? b.schedule_time.split(':').map(Number) : [0, 0];

                    const hoursDiff = timeA[0] - timeB[0];
                    const minutesDiff = timeA[1] - timeB[1];

                    return hoursDiff !== 0 ? hoursDiff : minutesDiff;
                  }
                })
                .map((cls, index) => {
                  const startTime = cls.schedule_time;
                  const endTime = calculateEndTime(startTime, cls.schedule_hour);
                  const hours = (cls.schedule_hour / 60).toFixed(2); // Convert minutes to hours
                  return (
                    <tr key={index}>
                      <td>{cls.subject_name}</td>
                      <td>{cls.nickname}</td>
                      <td>{moment(cls.date).format('dddd')}</td>
                      <td>{formatDate(cls.date)}</td>
                      <td>{`${formatTime(startTime)} - ${endTime}`}</td>
                      <td>{formatHours(hours)} {t('hrs')}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
          
        </Modal>

    </div>
  );
};

export default withRoleAccess(ClassDetailsStudent, ['superadmin', 'admin', 'student']);
