import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useParams, Link } from 'react-router-dom';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone, faHome, faGraduationCap, faPhoneAlt, faBirthdayCake, faTrash } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import './Student.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
 

const sortByDateAscending = (schedule) => {
  return schedule.sort((a, b) => new Date(a.date) - new Date(b.date));
};

function StudentDashboardAdmin() {
  const { t, i18n } = useTranslation();
  const { id } = useParams();
  const [student, setStudent] = useState(null);
  const [error, setError] = useState(null);
  const [attendanceLogs, setAttendanceLogs] = useState([]);
  const [billingHistory, setBillingHistory] = useState([]);
  const [testScores, setTestScores] = useState([]);
  const [studentInfo, setStudentInfo] = useState([]);
  const [newStudentInfo, setNewStudentInfo] = useState('');
  const [popupData, setPopupData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [classChangeLogs, setClassChangeLogs] = useState([]);
  const [absentCounts, setAbsentCounts] = useState({});
  const itemsPerPage = 10;

  const API_URL = process.env.REACT_APP_API_URL;
  
  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const getDayOfWeek = (dateString) => {
    try {
      const date = new Date(dateString);
      const dayIndex = date.getDay();
      const daysOfWeek = t('daysOfWeek', { returnObjects: true });
      return daysOfWeek[dayIndex];
    } catch (error) {
      console.error('Invalid date value:', dateString);
      return t('invalidDay');
    }
  };
// Function to calculate absences
const calculateAbsences = (attendanceData) => {
  const absences = {};
  attendanceData.forEach(attendance => {
    const statusLowerCase = attendance.status.toLowerCase();
    if (statusLowerCase === 'absent') {
      if (!absences[attendance.class_code]) {
        absences[attendance.class_code] = 1;
      } else {
        absences[attendance.class_code] += 1;
      }
    }
  });
  setAbsentCounts(absences);
};

  useEffect(() => {
    const fetchStudentData = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/students/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const studentData = response.data;
        setStudent({
          ...studentData,
          photourl: studentData.photourl || 'https://via.placeholder.com/150',
        });
      } catch (error) {
        console.error('Error fetching student data:', error);
        setError('Failed to fetch student data.');
      }
    };

    const fetchTestScores = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/test-scores/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setTestScores(response.data);
      } catch (error) {
        console.error('Error fetching test scores:', error);
      }
    };

    const fetchStudentInfo = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/student-info/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setStudentInfo(response.data);
      } catch (error) {
        console.error('Error fetching student info:', error);
      }
    };

    const fetchAttendanceLogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/attendance/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setAttendanceLogs(response.data);
        calculateAbsences(response.data);
      } catch (error) {
        console.error('Error fetching attendance logs:', error);
      }
    };

    const fetchBillingHistory = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/registrations/student/${id}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        const { registrations } = response.data;
  
        // Sort schedules for each registration
        const sortedRegistrations = registrations.map(reg => {
          const sortedSchedule = reg.schedule.sort((a, b) => {
            const dateTimeA = moment(`${a.date} ${a.time}`, 'YYYY-MM-DD HH:mm:ss');
            const dateTimeB = moment(`${b.date} ${b.time}`, 'YYYY-MM-DD HH:mm:ss');
            return dateTimeA - dateTimeB;
          });
  
          return { ...reg, schedule: sortedSchedule };
        });
  
        // Sort registrations by the earliest class in their schedule
        sortedRegistrations.sort((a, b) => {
          const earliestA = a.schedule[0] ? moment(`${a.schedule[0].date} ${a.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          const earliestB = b.schedule[0] ? moment(`${b.schedule[0].date} ${b.schedule[0].time}`, 'YYYY-MM-DD HH:mm:ss') : moment(0);
          return earliestA - earliestB;
        });
  
        setBillingHistory(sortedRegistrations);
      } catch (error) {
        console.error('Error fetching billing history:', error);
      }
    };

    fetchStudentData();
    fetchTestScores();
    fetchStudentInfo();
    fetchAttendanceLogs();
    fetchBillingHistory();
  }, [id, API_URL]);

  useEffect(() => {
    const fetchClassChangeLogs = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/class-change/class-change-logs`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setClassChangeLogs(response.data);
      } catch (error) {
        console.error('Error fetching class change logs:', error);
      }
    };
  
    fetchClassChangeLogs();
  }, [API_URL]);


  const handleAddStudentInfo = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(`${API_URL}/student-info`, {
        student_id: id,
        info_text: newStudentInfo
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudentInfo([response.data, ...studentInfo]);
      setNewStudentInfo('');
    } catch (error) {
      console.error('Error adding student info:', error);
    }
  };


  const handleDeleteStudentInfo = async (id) => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.delete(`${API_URL}/student-info/${id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });

      if (response.status === 200) {
        setStudentInfo(studentInfo.filter(info => info.id !== id));
      }
    } catch (error) {
      console.error('Error deleting student info:', error);
    }
  };

  const calculateEndTime = (startTime, duration) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [durationHours, durationMinutes] = duration.split(':').map(Number);
  
    let endHours = startHours + durationHours;
    let endMinutes = startMinutes + durationMinutes;
  
    if (endMinutes >= 60) {
      endHours += Math.floor(endMinutes / 60);
      endMinutes %= 60;
    }
  
    return `${endHours.toString().padStart(2, '0')}:${endMinutes.toString().padStart(2, '0')}`;
  };
  const formatDate = useCallback((dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  }, [i18n.language]);

  useEffect(() => {
    const recentDate = new Date();
    recentDate.setDate(recentDate.getDate() - 7);
  
    // Function to get notified absences from localStorage
    const getNotifiedAbsences = () => {
      const notified = localStorage.getItem('notifiedAbsences');
      return notified ? JSON.parse(notified) : [];
    };
  
    // Function to save notified absences to localStorage
    const saveNotifiedAbsences = (notifiedAbsences) => {
      localStorage.setItem('notifiedAbsences', JSON.stringify(notifiedAbsences));
    };
  
    const notifiedAbsences = new Set(getNotifiedAbsences());
  
    // Loop through the attendance logs
    attendanceLogs.forEach(attendance => {
      const attendanceDate = new Date(attendance.timestamp);
  
      // Check if the absence is within the last 7 days and if it's marked as absent
      if (attendanceDate >= recentDate && attendance.status.toLowerCase() === 'absent') {
        const count = absentCounts[attendance.class_code] || 1;
        const toastId = `${attendance.class_code}_${attendance.timestamp}`; // Unique ID for each toast
  
        // Only show the toast if the absence hasn't been notified before
        if (!notifiedAbsences.has(toastId)) {
          toast.info(
            `Student was marked absent for class ${attendance.class_code} on ${formatDate(attendance.timestamp)}. This is student's ${count}${getOrdinalSuffix(count)} absence for this class.`,
            {
              position: "top-right",
              autoClose: false, // Toast stays until closed
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              className: 'absent-toast', // Optional: Custom class for styling
              style: {
                backgroundColor: '#f8d7da', // Light red background
                color: '#721c24', // Dark red text
                border: '1px solid #f5c6cb', // Red border
              },
              // When the toast is closed, add it to the notified absences
              onClose: () => {
                notifiedAbsences.add(toastId); // Mark this absence as notified
                saveNotifiedAbsences([...notifiedAbsences]); // Save updated notified absences to localStorage
              },
            }
          );
        }
      }
    });
  }, [attendanceLogs, absentCounts, formatDate]);  
  

     // Function to get the ordinal suffix for numbers (1st, 2nd, 3rd, etc.)
     const getOrdinalSuffix = (i) => {
      const j = i % 10,
        k = i % 100;
      if (j === 1 && k !== 11) {
        return "st";
      }
      if (j === 2 && k !== 12) {
        return "nd";
      }
      if (j === 3 && k !== 13) {
        return "rd";
      }
      return "th";
    };

  
  const openSchedulePopup = (data) => {
    setPopupData(data);
  };

  const closePopup = () => {
    setPopupData(null);
  };

  const filterFutureClasses = (schedule) => {
    // Return the schedule without filtering for future classes
    return schedule;
  };

  const filteredAndSortedBillingHistory = useMemo(() => {
    return billingHistory.map(enrollment => ({
      ...enrollment,
      schedule: sortByDateAscending(filterFutureClasses(enrollment.schedule))
    })).filter(enrollment => enrollment.schedule.length > 0);
  }, [billingHistory]);

  const sortedAndFilteredSchedule = useMemo(() => {
    return filteredAndSortedBillingHistory
      .flatMap(enrollment => 
        enrollment.schedule.map(schedule => ({ ...schedule, class_code: enrollment.class_code }))
      )
      .sort((a, b) => moment(a.date + ' ' + a.time, 'YYYY-MM-DD HH:mm:ss') - moment(b.date + ' ' + b.time, 'YYYY-MM-DD HH:mm:ss'));
  }, [filteredAndSortedBillingHistory]);

  const paginatedSchedule = useMemo(() => {
    return sortedAndFilteredSchedule.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);
  }, [sortedAndFilteredSchedule, currentPage, itemsPerPage]);

  // Calculate total pages
  const totalPages = Math.ceil(sortedAndFilteredSchedule.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const getChangeReasonDisplay = (reason) => {
    switch (reason) {
      case 'admin_adjust':
        return { text: 'Admin', color: '#005181' };
      case 'cancelled_by_student':
        return { text: 'CL by S.', color: 'red' };
      case 'cancelled_by_teacher':
        return { text: 'CL by T.', color: 'orange' };
      case 'class_on_hold_by_student':
        return { text: 'On hold by S.', color: 'blue' };
      case 'class_on_hold_by_school':
        return { text: 'On hold by P.', color: 'green' };
      case 'others':
        return { text: 'Others', color: 'darkgrey' };
      default:
        return { text: reason || '', color: 'black' };
    }
  };

  if (error) return <p>{error}</p>;
  if (!student) return <p>Loading...</p>;


  return (
    <div className="student-dashboard-container-admin">
      <ToastContainer />
      <Sidebar />
      <Header />
      <div className="student-header">
        <div className="student-header-info">
          <h2>{student.firstname} {student.lastname} ({student.nickname})</h2>
        </div>
      </div>
      <div className="student-dashboard-content">
        <div className="student-profile">
          <h2>{t('profile')}</h2>
          <img src={student.photourl} alt={`${student.firstname} ${student.lastname}`} className="student-image2" />
            <p><FontAwesomeIcon icon={faEnvelope} className="icon" /><strong>{t('email')}:</strong> {student.email}</p>
            <p><FontAwesomeIcon icon={faPhone} className="icon" /><strong>{t('phone')}:</strong> {student.phone}</p>
            <p><FontAwesomeIcon icon={faHome} className="icon" /><strong>{t('address')}:</strong> {student.address}</p>
            <p><FontAwesomeIcon icon={faBirthdayCake} className="icon" /><strong>{t('dob')}:</strong> {formatDate(student.date_of_birth)}</p>
            <p><FontAwesomeIcon icon={faGraduationCap} className="icon" /><strong>{t('currentEducationLevel')}:</strong> {student.currenteducationlevel}</p>
            <p><FontAwesomeIcon icon={faPhoneAlt} className="icon" /><strong>{t('guardianContact')}:</strong> {student.guardiancontact}</p>
        </div>
        <div className="student-attendance">
        <h2>{t('testScores')}</h2>
          <div className="test-score-cards">
            {testScores.map((score, index) => (
              <div key={index} className="test-score-card">
                {/* Type and Test Name on the same row */}
                <div className="test-card-header">
                  <div className="header-row">
                    <h3 className="test-type">{score.type}</h3> {/* Test Type */}
                    <h4 className="test-name">{score.test_name}</h4> {/* Test Name */}
                  </div>
                </div>
                
                {/* Date and Score on the same row */}
                <div className="test-card-body">
                  <div className="score-row">
                    <span>{t('date')}:</span>
                    <span>{formatDate(score.test_date)}</span> {/* Test Date */}
                  </div>
                  <div className="score-row">
                    <span>{t('score')}:</span>
                    <span>{score.score}</span> {/* Test Score */}
                  </div>
                </div>

                {/* Note */}
                <div className="test-note">
                  <span>{t('note')}:</span>
                  <span>{score.note}</span> {/* Test Note */}
                </div>

                {/* Sub Type, if applicable */}
                {score.sub_type && (
                  <div className="score-row">
                    <span>{t('subType')}:</span>
                    <span>{score.sub_type}</span> {/* Sub Type */}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
        <div className="today-classes">
          <h2>{t('studentInfo')}</h2>
          <div className="student-info-list">
            {studentInfo.map((info, index) => (
              <div key={index} className="student-info-card">
                <div className="info-content">
                  <p>{formatDate(info.created_at)} - {info.info_text} <span>({info.created_by_nickname})</span></p>
                  <div className="info-actions">
                  <button onClick={() => handleDeleteStudentInfo(info.id)} className="delete-score">
                    <FontAwesomeIcon icon={faTrash} />
                  </button>
                </div>                </div>
              </div>
            ))}
          </div>

          <div className="student-info-form">
            <textarea
              value={newStudentInfo}
              onChange={(e) => setNewStudentInfo(e.target.value)}
              placeholder={t('enterStudentInfo')}
            />
            <button onClick={handleAddStudentInfo} className="add-button">{t('add')}</button>
          </div>
        </div>

      </div>

      <div className="all-classes">
      <div className="student-list-box">
          <h2>{t('classSchedule')}</h2>
          <table className="classtable">
              <thead>
                <tr>
                  <th>{t('date')}</th>
                  <th>{t('classCode')}</th>
                  <th>{t('classid')}</th>
                  <th>{t('subject')}</th>
                  <th>{t('teacher')}</th>
                  <th>{t('time')}</th>
                  <th>{t('hours')}</th>
                  <th>{t('classchange')}</th> {/* Column for change reason */}
                  <th>{t('attendance')}</th>
                </tr>
              </thead>
              <tbody>
              {paginatedSchedule.map((cls, idx) => {
                                const attendanceLog = attendanceLogs.find(log => log.classid === cls.classid);
                                const attendanceStatus = attendanceLog ? attendanceLog.status : 'Not Available';

                  // Find the matching change log for the current class
                  const log = classChangeLogs.find(log => log.classid === cls.classid);
                  const changereason = log ? log.changereason : '';

                  // Use the helper function to get the text and color for the changereason
                  const { text: changeReasonText, color: changeReasonColor } = getChangeReasonDisplay(changereason);

                  return (
                    <tr key={idx}>
                      <td>{formatDate(cls.date)}</td>
                      <td>{cls.class_code}</td>
                      <td>{cls.classid}</td>
                      <td>{cls.subject}</td>
                      <td>{cls.teacher}</td>
                      <td>{formatTime(cls.time)} - {calculateEndTime(cls.time, cls.hours)}</td>
                      <td>{cls.hours} {t('hr')}</td>
                      <td style={{ color: changeReasonColor }}>{changeReasonText}</td> {/* Display change reason */}
                      <td>
                        {/* Conditionally render attendance status with different background colors */}
                        {attendanceStatus === 'Present' && (
                          <span style={{
                            color: 'white',
                            backgroundColor: '#3498db',
                            padding: '5px 10px',
                            borderRadius: '4px',
                          }}>
                            {attendanceStatus}
                          </span>
                        )}
                        {attendanceStatus === 'Absent' && (
                          <span style={{
                            color: 'white',
                            backgroundColor: '#e74c3c',
                            padding: '5px 10px',
                            borderRadius: '4px',
                          }}>
                            {attendanceStatus}
                          </span>
                        )}
                        {attendanceStatus === 'Late' && (
                          <span style={{
                            color: 'white',
                            backgroundColor: '#f39c12',
                            padding: '5px 10px',
                            borderRadius: '4px',
                          }}>
                            {attendanceStatus}
                          </span>
                        )}
                        {!['Present', 'Absent', 'Late'].includes(attendanceStatus) && (
                          <span>{attendanceStatus}</span>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
              </table>
          <div className="pagination-container">
            {Array.from({ length: totalPages }, (_, i) => (
              <button
                key={i + 1}
                onClick={() => handlePageChange(i + 1)}
                className={currentPage === i + 1 ? 'active' : ''}
              >
                {i + 1}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="all-classes">
        <h2>{t('allCurrentEnrollments')}</h2>
        <table>
          <thead>
            <tr>
              <th>{t('classCode')}</th>
              <th>{t('subjects')}</th>
              <th>{t('schedule')}</th>
              <th>{t('totalHours')}</th>
              <th>{t('access')}</th>
            </tr>
          </thead>
          <tbody>
            {billingHistory.map((enrollment, index) => (
              <tr key={index}>
                <td>{enrollment.class_code}</td>
                <td>
                  {enrollment.subjects.map((subject, idx) => (
                    <div key={idx}>{subject.title}</div>
                  ))}
                </td>

                <td>
                  <button onClick={() => openSchedulePopup(sortByDateAscending(enrollment.schedule))}>{t('viewSchedule')}</button>
                </td>
                <td>{enrollment.total_hours} {t('hrs')}</td>
                <td>
                  <Link to={`/classdetails/${enrollment.groupid}`} style={{ textDecoration: 'none', fontWeight: 'bold' }}>
                    <button>{t('courseDetails')}</button>
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="additional-info">
      <div className="attendance-logs">
          <h2>{t('attendanceLogs')}</h2>
          <table>
            <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('course')}</th>
                <th>{t('classId')}</th>
                <th>{t('subject')}</th>
                <th>{t('attendance')}</th>
              </tr>
            </thead>
            <tbody>
              {attendanceLogs.map((log, index) => (
                <tr key={index}>
                  <td>{formatDate(log.date)}</td>
                  <td>{log.class_code}</td>
                  <td>{log.classid}</td>
                  <td>{log.subject_name}</td>
                  {/* Apply conditional class based on status */}
                  <td className={log.status === 'Absent' ? 'status-absent' : log.status === 'Late' ? 'status-late' : ''}>
                    {log.status}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>

      </div>

      {popupData && (
        <Modal isOpen={!!popupData} onClose={closePopup}>
          <h2>{t('scheduleDetails')}</h2>
          <table className="schedule-table">
            <thead>
              <tr>
                <th>{t('subjectName')}</th>
                <th>{t('teacherName')}</th>
                <th>{t('day')}</th>
                <th>{t('date')}</th>
                <th>{t('time')}</th>
                <th>{t('hours')}</th>
              </tr>
            </thead>
            <tbody>
              {popupData.map((slot, index) => (
                <tr key={index}>
                  <td>{slot.subject}</td>
                  <td>{slot.teacher}</td>
                  <td>{getDayOfWeek(slot.date)}</td>
                  <td>{formatDate(slot.date)}</td>
                  <td>{formatTime(slot.time)} - {calculateEndTime(slot.time, slot.hours)}</td>
                  <td>{slot.hours} {t('hr')}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </Modal>
      )}
    </div>
  );
}

export default withRoleAccess(StudentDashboardAdmin, ['admin', 'superadmin']);
