import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faChalkboardTeacher, faChalkboard, faSave } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import withRoleAccess from '../../hoc/withRoleAccess';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import CustomModal from './CustomModal';
import moment from 'moment-timezone';
import { confirmAlert } from 'react-confirm-alert'; // Import the confirmation dialog
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import the styles
import './Classes.css';

function AllClasses() {
  const { t, i18n } = useTranslation();
  const [classes, setClasses] = useState([]);
  const [teacherEvents, setTeacherEvents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedTeachers, setSelectedTeachers] = useState([]);
  const [teachers, setTeachers] = useState([]);
  const [classrooms, setClassrooms] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const [editingClassroomId, setEditingClassroomId] = useState(null);
  const [editedClassroomId, setEditedClassroomId] = useState('');
  const [editingTeacherId, setEditingTeacherId] = useState(null);
  const [editedTeacherId, setEditedTeacherId] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [searchTermTeacher, setSearchTermTeacher] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentEventInfo, setCurrentEventInfo] = useState(null);
  const [changeReason, setChangeReason] = useState('');
  const [changeNote, setChangeNote] = useState('');
  const [editingDateId, setEditingDateId] = useState(null);
  const [editedDate, setEditedDate] = useState('');

  const API_URL = process.env.REACT_APP_API_URL;

  const rowsPerPage = 45;


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  const formatTime = (timeString) => {
    const [hours, minutes] = timeString.split(':');
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')}`;
  };

  const formatScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value, index) => (index === 0 ? parseInt(value) : parseInt(value)));
    return `${hours}:${minutes.toString().padStart(2, '0')}`;
  };

  const getDayOfWeek = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = t('daysOfWeek', { returnObjects: true });
    return daysOfWeek[date.getDay()];
  };

  const parseScheduleHour = (scheduleHourString) => {
    const [hours, minutes] = scheduleHourString.split(' ').map((value, index) => (index === 0 ? parseInt(value) * 60 : parseInt(value)));
    return hours + minutes;
  };

  const fetchClasses = useCallback(async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }
      const response = await axios.get(`${API_URL}/classes`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });

      setClasses(response.data);
    } catch (error) {
      console.error('Error fetching classes:', error);
      setError('Failed to fetch classes. Please ensure you are logged in.');
    } finally {
      setLoading(false);
    }
  }, [API_URL]);

  const fetchEvents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      if (!token) {
        setError("No token found");
        setLoading(false);
        return;
      }
      const response = await axios.get(`${API_URL}/events`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setTeacherEvents(response.data);
    } catch (error) {
      console.error('Error fetching events:', error);
      setError('Failed to fetch events. Please ensure you are logged in.');
    }
  }, [API_URL]);

  const fetchTeacherEvents = useCallback(async (teacherIds) => {
    if (teacherIds.length === 0) {
      setTeacherEvents([]);
      return; // If no teacher selected, return empty events
    }
  
    try {
      const token = localStorage.getItem('token');
      const promises = teacherIds.map(teacherId =>
        axios.get(`${API_URL}/teachers/${teacherId}/events`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
      );
      const results = await Promise.all(promises);
      const allTeacherEvents = results.flatMap(result => result.data);
      setTeacherEvents(allTeacherEvents);
    } catch (error) {
      console.error('Error fetching teacher events:', error);
      setError('Failed to fetch teacher events. Please try again later.');
    }
  }, [API_URL]);
  
  useEffect(() => {
    fetchClasses();
    fetchEvents(); 
    setTeacherEvents([]); 
  }, [fetchClasses, fetchEvents]);
  

  useEffect(() => {
    const fetchTeachers = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/teachers`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
  
        const teachersWithColors = response.data.map((teacher) => ({
          ...teacher,
          color: teacher.color || '#000000',
        }));
  
        setTeachers(teachersWithColors);
  
        // Debugging: Log the teachers to verify colors
      } catch (error) {
        console.error('Error fetching teachers:', error);
      }
    };

    fetchTeachers();
  }, [API_URL]);

  useEffect(() => {
    const fetchClassrooms = async () => {
      try {
        const token = localStorage.getItem('token');
        const response = await axios.get(`${API_URL}/classrooms`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setClassrooms(response.data);
      } catch (error) {
        console.error('Error fetching classrooms:', error);
      }
    };

    fetchClassrooms();
  }, [API_URL]);

  useEffect(() => {
    if (selectedTeachers.length > 0) {
      fetchTeacherEvents(selectedTeachers);
    } else {
      setTeacherEvents([]);
    }
  }, [selectedTeachers, fetchTeacherEvents]);

  const handleTeacherChange = (teacherId) => {
    setSelectedTeachers((prevSelectedTeachers) =>
      prevSelectedTeachers.includes(teacherId)
        ? prevSelectedTeachers.filter((t) => t !== teacherId)
        : [...prevSelectedTeachers, teacherId]
    );
  };

  const handleEditDateClick = (classId, date) => {
    setEditingDateId(classId);
    setEditedDate(date); // Set initial date
  };

  

  const handleClassroomChangeDropdown = (e) => {
    setEditedClassroomId(e.target.value);
  };

  const handleTeacherChangeDropdown = (e) => {
    setEditedTeacherId(e.target.value);
  };

  const handleEditClassroomClick = (classId, classroomId) => {
    setEditingClassroomId(classId);
    setEditedClassroomId(classroomId); // Set initial classroom ID
  };

  const handleEditTeacherClick = (classId, teacherId) => {
    setEditingTeacherId(classId);
    setEditedTeacherId(teacherId); // Set initial teacher ID
  };

  const handleSaveClassroomClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        classroom_id: editedClassroomId,
      };

      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setEditingClassroomId(null);
      setEditedClassroomId('');
      fetchClasses(); // Refresh the class list
    } catch (error) {
      console.error('Error updating classroom:', error);
    }
  };

  const handleSaveTeacherClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        teacher_id: editedTeacherId,
      };
  
      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, { 
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      setEditingTeacherId(null);
      setEditedTeacherId('');
      fetchClasses(); // Refresh the class list
    } catch (error) {
      console.error('Error updating teacher:', error);
    }
  };

  const handleSaveDateClick = async (classId) => {
    try {
      const token = localStorage.getItem('token');
      const updatedClass = {
        date: editedDate,
      };
  
      await axios.put(`${API_URL}/classes/${classId}`, updatedClass, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
  
      setEditingDateId(null);
      setEditedDate('');
      fetchClasses(); // Refresh the class list after update
    } catch (error) {
      console.error('Error updating date:', error);
    }
  };
  

  const checkOverlap = (newStart, newEnd, teacherId, eventId, allClasses, teacherEvents) => {
    return [...allClasses, ...teacherEvents].some(event => {
      // Skip the current event being moved/resized
      if (event.classid?.toString() === eventId || event.eventid?.toString() === eventId) return false;
      
      // Check all events for the same teacher
      if (event.teacher_id !== teacherId && event.userid !== teacherId) return false;
  
      const eventStart = moment(event.start || `${event.date} ${event.schedule_time}`);
      const eventEnd = moment(event.end || `${event.date} ${event.end_time}`);
  
      return (
        newStart.isBetween(eventStart, eventEnd, null, '[)') ||
        newEnd.isBetween(eventStart, eventEnd, null, '(]') ||
        (newStart.isSameOrBefore(eventStart) && newEnd.isSameOrAfter(eventEnd))
      );
    });
  };

  const handleEventDrop = (info) => {
    const eventId = info.event.id;
    const classItem = classes.find(cls => cls.classid.toString() === eventId);
  
    if (classItem && classItem.status === 'approved') {
      confirmAlert({
        title: t('Action Not Allowed'),
        message: t('You cannot change an approved class.'),
        buttons: [{ label: t('ok'), onClick: () => info.revert() }],
      });
      return;
    }
  
    const newStart = moment(info.event.start);
    const newEnd = moment(info.event.end);
    const teacherId = classItem?.teacher_id;
  
    const isOverlapping = checkOverlap(newStart, newEnd, teacherId, eventId, classes, teacherEvents);
  
    if (isOverlapping) {
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class or event for the same teacher. Please choose a different time.'),
        buttons: [{ label: t('Ok'), onClick: () => info.revert() }],
      });
      return;
    }
  
    setCurrentEventInfo(info);
    setShowModal(true);
  };
  
  const handleEventResize = (info) => {
    const eventId = info.event.id;
    const classItem = classes.find(cls => cls.classid.toString() === eventId);
  
    if (classItem && classItem.status === 'approved') {
      confirmAlert({
        title: t('Action Not Allowed'),
        message: t('You cannot change an approved class.'),
        buttons: [{ label: t('ok'), onClick: () => info.revert() }],
      });
      return;
    }
  
    const newStart = moment(info.event.start);
    const newEnd = moment(info.event.end);
    const teacherId = classItem?.teacher_id;
  
    const isOverlapping = checkOverlap(newStart, newEnd, teacherId, eventId, classes, teacherEvents);
  
    if (isOverlapping) {
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class or event for the same teacher. Please choose a different time.'),
        buttons: [{ label: t('Ok'), onClick: () => info.revert() }],
      });
      return;
    }
  
    setCurrentEventInfo(info);
    setShowModal(true);
  };

  const handleSubmitModal = async () => {
    const { event } = currentEventInfo;
    const classItem = classes.find(cls => cls.classid.toString() === event.id);

    // Define original and new times
    const originalStartTime = classItem.schedule_time; 
    const originalEndTime = classItem.end_time; 
    const newStartTime = event.start.toTimeString().split(' ')[0].substring(0, 5);
    const newEndTime = event.end.toTimeString().split(' ')[0].substring(0, 5);

    // Format dates with moment-timezone
    const originalDate = moment.tz(classItem.date, 'Asia/Bangkok').format('YYYY-MM-DD');
    const newDate = moment.tz(event.start, 'Asia/Bangkok').format('YYYY-MM-DD');

    const updatedEvent = {
        id: event.id,
        date: newDate,
        schedule_time: newStartTime,
        schedule_hour: ((event.end - event.start) / 60000).toFixed(0)
    };

    try {
        const token = localStorage.getItem('token');
        await axios.put(`${API_URL}/classes/${updatedEvent.id}`, updatedEvent, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        await axios.post(`${API_URL}/class-change/class-change-logs`, {
            classid: updatedEvent.id,
            teacherid: classItem.teacher_id,
            originaldate: originalDate,
            originalstarttime: originalStartTime,
            originalendtime: originalEndTime,
            newdate: newDate,
            newstarttime: newStartTime,
            newendtime: newEndTime,
            changereason: changeReason,
            note: changeNote,
            updateby: token // Use token to determine the updating user
        }, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        });

        setShowModal(false);
      fetchClasses(); // Refresh the class list after update
    } catch (error) {
      console.error('Error updating event:', error);
      setShowModal(false);
      setCurrentEventInfo(null);
    }
  };
  

  const filteredClasses = classes.filter((classItem) => {
    const isSearchMatch =
      classItem.subject_name?.toLowerCase().includes(searchTerm.toLowerCase()) ||
      classItem.class_code?.toLowerCase().includes(searchTerm.toLowerCase());
    const isTeacherMatch =
      selectedTeachers.length > 0
        ? selectedTeachers.includes(classItem.teacher_id)
        : true;
    return isSearchMatch && isTeacherMatch;
  });

  const formatClassToEvent = (classItem) => {
    const startDate = new Date(classItem.date);
    const [startHours, startMinutes] = classItem.schedule_time.split(':').map(Number);

    if (isNaN(startHours) || isNaN(startMinutes)) {
        console.error('Invalid schedule time format:', classItem.schedule_time);
        return null;
    }

    startDate.setHours(startHours);
    startDate.setMinutes(startMinutes);
    startDate.setSeconds(0);
    startDate.setMilliseconds(0);

    if (isNaN(startDate.getTime())) {
        console.error('Invalid start date or time:', classItem.date, classItem.schedule_time, startDate);
        return null;
    }

    const totalMinutes = parseScheduleHour(classItem.schedule_hour || '');
    const endDateTime = new Date(startDate.getTime() + totalMinutes * 60000);

    if (isNaN(endDateTime.getTime())) {
        console.error('Invalid end date or time:', startDate, endDateTime, classItem.schedule_hour);
        return null;
    }

    const teacher = teachers.find((t) => t.userid === classItem.teacher_id);

    const titleLines = [
        `ID: ${classItem.classid}`, 
        `${classItem.class_code}`,
        `${classItem.subject_name}`, 
        `${classItem.nickname} / Room: ${classItem.classroom_number}`,
    ];

    return {
        id: classItem.classid.toString(),  
        title: titleLines.join('<br />'), 
        start: startDate.toISOString(), 
        end: endDateTime.toISOString(),
        color: teacher ? teacher.color : '#333333', 
        backgroundColor: teacher ? teacher.color : '#333333',
    };
};


  
  const formatEventToBackgroundEvent = (event) => {
    const startDate = new Date(event.start);
    const endDateTime = new Date(event.end);

    const teacher = teachers.find(t => t.userid === event.userid);

    return {
      id: event.eventid,
      title: `${event.title} - ${event.userid}`, 
      start: startDate.toISOString(),
      end: endDateTime.toISOString(),
      display: 'background',
      color: teacher ? teacher.color : '#444444',
    };
  };

  const calendarEvents = [
    ...filteredClasses.map(formatClassToEvent).filter((event) => event !== null),
    ...(selectedTeachers.length > 0 ? teacherEvents.map(formatEventToBackgroundEvent).filter((event) => event !== null) : []),
  ];
  

  // Separate future and past classes
  const today = new Date().setHours(0, 0, 0, 0);
  const futureClasses = filteredClasses.filter((classItem) => new Date(classItem.date) >= today);
  const pastClasses = filteredClasses.filter((classItem) => new Date(classItem.date) < today).reverse(); // Reverse past classes

  // Concatenate past and future classes for pagination
  const allClasses = [...pastClasses.reverse(), ...futureClasses];

  // Calculate pagination
  const totalPages = Math.ceil(allClasses.length / rowsPerPage);
  const pastPages = Math.ceil(pastClasses.length / rowsPerPage);

  // Set the initial page to the first page of future classes
  useEffect(() => {
    if (pastPages > 0) {
      setCurrentPage(pastPages + 1);
    }
  }, [pastPages]);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setShowAll(false); // Reset showAll to false when navigating pages
  };

  const currentClasses = showAll
    ? allClasses
    : currentPage <= pastPages
      ? pastClasses.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage).reverse()
      : futureClasses.slice((currentPage - pastPages - 1) * rowsPerPage, (currentPage - pastPages) * rowsPerPage);

  
      const handleSearchChange = (e) => {
        setSearchTermTeacher(e.target.value.toLowerCase());
      };
    
      const filteredTeachers = teachers.filter(
        (teacher) =>
          (teacher.nickname && teacher.nickname.toLowerCase().includes(searchTermTeacher)) ||
          (teacher.firstname && teacher.firstname.toLowerCase().includes(searchTermTeacher))
      );
        

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  const csvData = currentClasses.map((classItem) => ({
    Date: formatDate(classItem.date),
    'Schedule Day': getDayOfWeek(classItem.date),
    'Class Code': classItem.class_code,
    'Subject Name': classItem.subject_name,
    'Teacher Name': classItem.teacher_name,
    'Schedule Time': formatTime(classItem.schedule_time),
    'Schedule Hours': classItem.schedule_hour,
    Room: classItem.classroom_number,
    Type: classItem.isprivate ? 'Private' : 'Group',
  }));

  return (
    <div className="class-container">
      <Sidebar />
      <Header />

      <div className="filter-container-allclass">
        <input
          type="text"
          placeholder={t('searchByName')}
          value={searchTermTeacher}
          onChange={handleSearchChange}
          style={{
            marginBottom: '10px',
            padding: '5px',
            width: '100%',
            boxSizing: 'border-box',
          }}
        />
        {filteredTeachers.map((teacher) => (
        <div key={teacher.userid} className="checkbox-container">
          <label>
            <span
              className="color-tab"
              style={{ backgroundColor: teacher.color }}
            ></span>
            <input
              type="checkbox"
              value={teacher.userid}
              checked={selectedTeachers.includes(teacher.userid)}
              onChange={() => handleTeacherChange(teacher.userid)}
            />
            <span className="checkmark"></span>
            <span>
              {teacher.nickname} {teacher.firstname}
            </span>
          </label>
        </div>
      ))}
    </div>

      <div className="class-calendar-container">
      <FullCalendar
        key={calendarEvents.length} 
        plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
        initialView="timeGridWeek"
        allDaySlot={false}
        locale={i18n.language === 'th' ? thLocale : 'en'}
        headerToolbar={{
          left: 'prev,next today',
          center: 'title',
          right: 'dayGridMonth,timeGridWeek,timeGridDay',
        }}
        slotMinTime="06:00:00"
        slotMaxTime="21:00:00"
        events={calendarEvents}
        editable={true}
        droppable={true}
        eventDrop={handleEventDrop}
        eventResize={handleEventResize}
        eventContent={(arg) => {
          const event = arg.event;
          const startTime = event.start
            ? event.start.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
            : '';
          const endTime = event.end
            ? event.end.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit', hour12: false })
            : '';
          return {
            html: `
              <div style="background-color: ${event.backgroundColor}; padding: 2px; border-radius: 4px;">
                <div>${startTime} - ${endTime}</div>
                <div style="white-space:pre-line">${event.title}</div>
              </div>
            `,
          };
        }}
      />



      </div>

      <div className="classes-table-container">
        <div className="all-class-list">
          <h1>{t('allClassLists')}</h1>

          <div className="search-container">
            <input
              type="text"
              placeholder={t('searchBySubject')}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </div>

          <div className="pagination-container">
            <button
              onClick={() => {
                setShowAll(true);
                setCurrentPage(1);
              }}
              className="page-button"
            >
              {t('all')}
            </button>
            <button
              className={`page-button ${currentPage === 1 ? 'disabled' : ''}`}
              onClick={() => currentPage > 1 && paginate(currentPage - 1)}
              disabled={currentPage === 1}
            >
              {t('prev')}
            </button>
            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => paginate(index + 1)}
                className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
              >
                {index + 1}
              </button>
            ))}
            <button
              className={`page-button ${currentPage === totalPages ? 'disabled' : ''}`}
              onClick={() => currentPage < totalPages && paginate(currentPage + 1)}
              disabled={currentPage === totalPages}
            >
              {t('next')}
            </button>
            <CSVLink
              data={csvData}
              filename={`class_list_${new Date().toISOString()}.csv`}
              className="export-button"
            >
              <FontAwesomeIcon icon={faFileExcel} /> {t('exportCSV')}
            </CSVLink>
          </div>

          <table className="classes-table">
            <thead>
              <tr>
                <th>{t('date')}</th>
                <th>{t('change')}</th>
                <th>{t('day')}</th>
                <th>{t('code')}</th>
                <th>{t('subject')}</th>
                <th>{t('tutor')}</th>
                <th>{t('start')}</th>
                <th>{t('end')}</th>
                <th>{t('hours')}</th>
                <th>{t('type')}</th>
                <th>{t('room')}</th>
                <th>{t('room')}</th>
                <th>{t('tutor')}</th>
                <th>{t('status')}</th>
                <th>{t('classid')}</th>
              </tr>
            </thead>
            <tbody>
              {currentClasses.map((classItem) => (
                <tr key={classItem.classid}>
                  <td>
                    {editingDateId === classItem.classid ? (
                      <input 
                        type="date" 
                        value={editedDate} 
                        onChange={(e) => setEditedDate(e.target.value)} 
                      />
                    ) : (
                      formatDate(classItem.date)
                    )}
                  </td>
                  <td>
                    <div>
                      {editingDateId === classItem.classid ? (
                        <button onClick={() => handleSaveDateClick(classItem.classid)} className="save-button">
                          <FontAwesomeIcon icon={faSave} />
                        </button>
                      ) : (
                        <button onClick={() => handleEditDateClick(classItem.classid, classItem.date)} className="delete-button">
                          <FontAwesomeIcon icon={faChalkboard} />
                        </button>
                      )}
                    </div>
                  </td>
                  <td>{getDayOfWeek(classItem.date)}</td>
                  <td>{classItem.class_code}</td>
                  <td>{classItem.subject_name}</td>
                  <td>{editingTeacherId === classItem.classid ? (
                    <select value={editedTeacherId} onChange={handleTeacherChangeDropdown}>
                      <option value="">{t('selectTeacher')}</option>
                      {teachers.map((teacher) => (
                        <option key={teacher.userid} value={teacher.userid}>
                          {teacher.nickname} {teacher.firstname}
                        </option>
                      ))}
                    </select>
                  ) : classItem.nickname}</td>
                  <td>{formatTime(classItem.schedule_time)}</td>
                  <td>{formatTime(classItem.end_time)}</td>
                  <td>{formatScheduleHour(classItem.schedule_hour)} {t('hr')}</td>
                  <td>{classItem.isprivate ? t('private') : t('group')}</td>
                  <td>{editingClassroomId === classItem.classid ? (
                    <select value={editedClassroomId} onChange={handleClassroomChangeDropdown}>
                      <option value="">{t('selectClassroom')}</option>
                      {classrooms.map((classroom) => (
                        <option key={classroom.classroomid} value={classroom.classroomid}>
                          {classroom.number}
                        </option>
                      ))}
                    </select>
                  ) : classItem.classroom_number}</td>
                  <td><div>
                    {editingClassroomId === classItem.classid ? (
                      <button onClick={() => handleSaveClassroomClick(classItem.classid)} className="save-button"><FontAwesomeIcon icon={faSave} /></button>
                    ) : (
                      <button onClick={() => handleEditClassroomClick(classItem.classid, classItem.classroom_id)} className="edit-button"><FontAwesomeIcon icon={faChalkboard} /></button>
                    )}
                  </div></td>
                  <td>
                    <div>
                      {editingTeacherId === classItem.classid ? (
                        <button onClick={() => handleSaveTeacherClick(classItem.classid)} className="save-button">
                          <FontAwesomeIcon icon={faSave} />
                        </button>
                      ) : (
                        <button onClick={() => handleEditTeacherClick(classItem.classid, classItem.teacher_id)} className="edit-button">
                          <FontAwesomeIcon icon={faChalkboardTeacher} />
                        </button>
                      )}
                    </div>
                  </td>
                  
                  <td>{classItem.status}</td>
                  <td>{classItem.classid}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="all-class-card-list">
          {currentClasses.map((classItem) => (
            <div key={classItem.classid} className="all-class-card">
              <div className="all-class-card-header">
                {classItem.class_code} - {classItem.subject_name}
              </div>
              <div className="all-class-card-body">
                <div className="class-info">
                  <label>{t('date')}: </label>{formatDate(classItem.date)}
                </div>
                <div className="class-info">
                  <label>{t('day')}: </label>{getDayOfWeek(classItem.date)}
                </div>
                <div className="class-info">
                  <label>{t('tutor')}: </label>{classItem.teacher_name}
                </div>
                <div className="class-info">
                  <label>{t('start')}: </label>{formatTime(classItem.schedule_time)}
                </div>
                <div className="class-info">
                  <label>{t('end')}: </label>{formatTime(classItem.end_time)}
                </div>
                <div className="class-info">
                  <label>{t('hours')}: </label>{formatScheduleHour(classItem.schedule_hour)}
                </div>
                <div className="class-info">
                  <label>{t('room')}: </label>{classItem.classroom_number}
                </div>
                <div className="class-info">
                  <button className="edit-button" onClick={() => handleEditClassroomClick(classItem.classid, classItem.classroom_id)}>
                    <FontAwesomeIcon icon={faChalkboard} /> {t('edit')}
                  </button>
                </div>
                <div className="class-info">
                  <button className="edit-button" onClick={() => handleEditTeacherClick(classItem.classid, classItem.teacher_id)}>
                    <FontAwesomeIcon icon={faChalkboardTeacher} /> {t('edit')}
                  </button>
                </div>
              </div>
            </div>
             ))}
             </div>
        </div>
      </div>
      <CustomModal
        isOpen={showModal}
        onClose={() => setShowModal(false)}
        onSubmit={handleSubmitModal}
        reason={changeReason}
        setReason={setChangeReason}
        note={changeNote}
        setNote={setChangeNote}
      />
    </div>
  );
}

export default withRoleAccess(AllClasses, ['admin', 'superadmin']);
