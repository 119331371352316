import React, { useEffect, useState } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { CSVLink } from 'react-csv'; // Import CSVLink
import './Teacher.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { useTranslation } from 'react-i18next';

const generateMonthYearOptions = () => {
    const options = [];
    const startYear = 2024;
    const startMonth = 7; // August (0-indexed, so January is 0, August is 7)
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
  
    for (let year = startYear; year <= currentYear + 5; year++) { // Loop up to the next 5 years
      for (let month = 0; month < 12; month++) {
        // Ensure we only start from August 2024 onwards
        if (year === startYear && month < startMonth) continue;
  
        // Construct the month-year label
        const monthYear = new Date(year, month).toLocaleString('default', {
          month: 'short',
          year: 'numeric'
        }).toUpperCase();
  
        options.push({ value: `${year}-${month + 1}`, label: monthYear });
  
        // Stop if we go past the current month in the current year
        if (year === currentYear && month >= currentMonth) {
          continue;
        }
      }
    }
  
    return options;
  };
  

  const TutorClassLog = () => {
    const { t } = useTranslation();
    const [classes, setClasses] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);
    const [selectedMonthYear, setSelectedMonthYear] = useState('');
    const [statusFilter, setStatusFilter] = useState('');
    const [adminapproveFilter, setAdminapproveFilter] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [tutorFilter, setTutorFilter] = useState('');
    const itemsPerPage = 150;

    useEffect(() => {
      const fetchClasses = async () => {
        try {
          const token = localStorage.getItem('token');
          const response = await axios.get(`${process.env.REACT_APP_API_URL}/classes`, {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          });
          setClasses(response.data);
          setFilteredClasses(response.data); // Initially display all classes
        } catch (error) {
          console.error('Error fetching classes:', error);
        }
      };
  
      fetchClasses();
    }, []);
  
    useEffect(() => {
      const filtered = classes.filter(cls => {
        const classDate = new Date(cls.date);
        const classMonthYear = `${classDate.getFullYear()}-${classDate.getMonth() + 1}`;
        const isMonthYearMatch = selectedMonthYear ? classMonthYear === selectedMonthYear : true;
        const isStatusMatch = statusFilter ? cls.status === statusFilter : true;
        const isAdminapproveMatch = adminapproveFilter ? cls.adminapprove === adminapproveFilter : true;
        const isTutorMatch = tutorFilter ? `(${cls.nickname}) ${cls.teacher_name}`.toLowerCase().includes(tutorFilter.toLowerCase()) : true;
        return isMonthYearMatch && isStatusMatch && isAdminapproveMatch && isTutorMatch;
      });
    
      setFilteredClasses(filtered);
      setCurrentPage(1); // Reset to first page after filtering
    }, [selectedMonthYear, statusFilter, adminapproveFilter, tutorFilter, classes]);
  

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  const currentClasses = filteredClasses.slice(startIndex, endIndex);
  const totalPages = Math.ceil(filteredClasses.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getUniqueTutors = () => {
    const tutors = classes.map(cls => `(${cls.nickname}) ${cls.teacher_name}`);
    return [...new Set(tutors)].sort();
  };

  const monthYearOptions = generateMonthYearOptions();

  const handleAdminApprove = (classId, status) => {
    // Show confirmation alert before proceeding
    confirmAlert({
      title: 'Confirm Admin Approval',
      message: 'Are you sure you want to confirm the admin approval for this class?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.put(`${process.env.REACT_APP_API_URL}/classes/${classId}/adminapprove`, {
                adminapprove: status
              }, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              });
              // Update the class state with the new approval status
              setClasses(prevClasses => prevClasses.map(cls => 
                cls.classid === classId ? { ...cls, adminapprove: status } : cls
              ));
            } catch (error) {
              console.error('Error confirming hours:', error);
            }
          }
        },
        {
          label: 'No',
          onClick: () => {

          }
        }
      ]
    });
  };
  const csvData = filteredClasses.map(cls => ({
    No: cls.classid,
    Date: new Date(cls.date).toLocaleDateString(),
    ClassCode: cls.class_code,
    ClassID: cls.classid,
    Subject: cls.subject_name,
    Teacher: `(${cls.nickname}) ${cls.teacher_name}`,
    Time: cls.schedule_time,
    ScheduleHour: cls.schedule_hour,
    Status: cls.status,
    AdminApprove: cls.adminapprove
  }));

  return (
    <div className="teacher-hours">
      <h2>{t('tutorclasslogs')}</h2>
  
      <div className="filter-container">
        <select
          value={selectedMonthYear}
          onChange={(e) => setSelectedMonthYear(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('All Months/Years')}</option>
          {monthYearOptions.map(option => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        <select
          value={tutorFilter}
          onChange={(e) => setTutorFilter(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('All Tutors')}</option>
          {getUniqueTutors().map(tutor => (
            <option key={tutor} value={tutor}>
              {tutor}
            </option>
          ))}
        </select>
  
        <select
          value={statusFilter}
          onChange={(e) => setStatusFilter(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('classstatuses')}</option>
          <option value="approved">{t('confirmed')}</option>
          <option value="pending">{t('confirmpending')}</option>
        </select>
  
        <select
          value={adminapproveFilter}
          onChange={(e) => setAdminapproveFilter(e.target.value)}
          className="filter-select"
        >
          <option value="">{t('adminapprove')}</option>
          <option value="adminconfirm">{t('Admin Confirmed')}</option>
          <option value="pending">{t('Pending')}</option>
        </select>
      </div>
  
      <CSVLink data={csvData} filename={`tutor_class_log_${selectedMonthYear || 'all'}.csv`}>
        <button><FontAwesomeIcon icon={faFileExcel} /> {t('exportToCSV')}</button>
      </CSVLink>
  
      <div className="pagination-container">
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} className="page-button">
          {t('Previous')}
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
          >
            {index + 1}
          </button>
        ))}
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages} className="page-button">
          {t('Next')}
        </button>
      </div>
  
      {/* Wrap the table inside a div with overflow-x */}
      <div style={{ overflowX: 'auto' }}>
        <table>
          <thead>
            <tr>
              <th>{t('No')}</th>
              <th>{t('date')}</th>
              <th>{t('classcode')}</th>
              <th>{t('classid')}</th>
              <th>{t('subject')}</th>
              <th>{t('teacher')}</th>
              <th>{t('time')}</th>
              <th>{t('scheduleHour')}</th>
              <th>{t('confirmteach')}</th>
              <th>{t('adminapprove')}</th>
            </tr>
          </thead>
          <tbody>
            {currentClasses.map((cls, index) => (
              <tr key={cls.classid}>
                <td>{startIndex + index + 1}</td>
                <td>{new Date(cls.date).toLocaleDateString()}</td>
                <td>{cls.class_code}</td>
                <td>{cls.classid}</td>
                <td>{cls.subject_name}</td>
                <td>{cls.nickname}</td>
                <td>{cls.schedule_time}</td>
                <td>{cls.schedule_hour}</td>
                <td className={cls.status === 'approved' ? 'status-approved' : 'status-pending'}>
                  {cls.status === 'approved' ? t('HOURSAPPROVED') : t('confirmpending')}
                </td>
                <td className={cls.adminapprove === 'adminconfirm' ? 'admin-status-approved' : 'admin-status-pending'}>
                  {cls.adminapprove === 'adminconfirm' ? (
                    <span>{t('Admin Confirmed')}</span>
                  ) : (
                    <button onClick={() => handleAdminApprove(cls.classid, 'adminconfirm')}>
                      {t('Admin Confirm')}
                    </button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
  
};

export default withRoleAccess(TutorClassLog, ['superadmin', 'admin']);