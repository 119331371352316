import React, { useState, useEffect } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarAlt, faClock, faTasks } from '@fortawesome/free-solid-svg-icons';
import TaskPopup from './TaskPopup';
import { getTasks, updateTask } from './apiService';
import moment from 'moment';
import 'moment-timezone';
import axios from 'axios';
import thLocale from '@fullcalendar/core/locales/th'; // Import Thai locale
import { useTranslation } from 'react-i18next';
import './Calendar.css'; // Import the TaskPopup CSS
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css

const Calendar = ({ onTasksChange, onClassesChange }) => {
  const { t, i18n } = useTranslation();
  const [tasks, setTasks] = useState([]);
  const [classes, setClasses] = useState([]);
  const [isTaskPopupOpen, setIsTaskPopupOpen] = useState(false);
  const [selectedTask, setSelectedTask] = useState(null);
  const [dateSelected, setDateSelected] = useState(null);
  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    fetchTasks();
    fetchClasses();
  // eslint-disable-next-line
  }, []);

  const fetchTasks = async () => {
    try {
      const fetchedTasks = await getTasks();
      const transformedTasks = fetchedTasks.map(task => ({
        id: `task-${task.eventid.toString()}`, // Add a prefix to ensure uniqueness
        title: task.title,
        start: moment(task.start).local().toISOString(),
        end: moment(task.end).local().toISOString(),
        description: task.description,
        type: 'task',
        schedule_hour: moment(task.end).diff(moment(task.start), 'minutes')
      }));
      setTasks(transformedTasks);
      onTasksChange(transformedTasks); // Notify parent component of the task changes
    } catch (error) {
      console.error("Failed to fetch tasks:", error);
    }
  };

  const fetchClasses = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/classes/teacher`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      const transformedClasses = response.data.map(cls => {
        const startDate = moment(cls.date).format('YYYY-MM-DD') + 'T' + cls.schedule_time;
        const endDate = moment(cls.date).format('YYYY-MM-DD') + 'T' + cls.end_time;
        return {
          id: `class-${cls.classid}`, // Add a prefix to ensure uniqueness
          title: `${cls.class_code} - ${cls.subject_name} - ${cls.classroom_number}`, 
          start: startDate,
          end: endDate,
          type: 'class',
        };
      });
      setClasses(transformedClasses);
      onClassesChange(transformedClasses); 
    } catch (error) {
      console.error("Failed to fetch classes:", error);
    }
  };

  const handleDateClick = (arg) => {
    const newStart = moment(arg.start);
    const newEnd = moment(arg.end);
  
    // Check for any overlap with existing classes
    const isOverlapping = classes.some(cls => {
      const classStart = moment(cls.start);
      const classEnd = moment(cls.end);
  
      // Overlap check: new task start or end falls within the class time, or fully overlaps
      return (
        newStart.isBetween(classStart, classEnd, null, '[)') || 
        newEnd.isBetween(classStart, classEnd, null, '(]') || 
        (newStart.isBefore(classStart) && newEnd.isAfter(classEnd)) // full overlap
      );
    });
  
    if (isOverlapping) {
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => {}
          }
        ]
      });
      return; // Prevent task creation if overlap exists
    }
  
    if (arg.view.type !== 'dayGridMonth') {
      setDateSelected({ start: arg.start, end: arg.end });
      setSelectedTask(null);
      setIsTaskPopupOpen(true);
    }
  };

  const handleEventClick = ({ event }) => {
    const taskToEdit = tasks.find(task => task.id === event.id);
    if (taskToEdit) {
      setSelectedTask(taskToEdit);
      setIsTaskPopupOpen(true);
    }
  };

  const handleEventDrop = (info) => {
    const newStart = moment(info.event.start);
    const newEnd = moment(info.event.end);
    const eventId = info.event.id;
  
    // Check for overlap with existing tasks and classes
    const isOverlapping = [...tasks, ...classes].some(event => {
      if (event.id === eventId) return false; // Skip the same event that is being dragged
  
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
  
      // Overlap check: new event start or end falls within an existing event time, or fully overlaps
      return (
        newStart.isBetween(eventStart, eventEnd, null, '[)') || 
        newEnd.isBetween(eventStart, eventEnd, null, '(]') || 
        (newStart.isBefore(eventStart) && newEnd.isAfter(eventEnd)) // full overlap
      );
    });
  
    if (isOverlapping) {
      // Prevent drop and show confirmation alert if there is an overlap
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class or event. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => info.revert(), // Revert the event to its original position
          }
        ]
      });
      return; // Stop further execution if overlap exists
    }
  
    // Proceed with the confirmation and task update if there is no overlap
    confirmAlert({
      title: t('Confirm to submit'),
      message: t('Are you sure you want to change the event?'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => confirmEventChange(info),
        },
        {
          label: t('no'),
          onClick: () => info.revert(),
        },
      ],
    });
  };
  

  const handleEventResize = (info) => {
    const newStart = moment(info.event.start);
    const newEnd = moment(info.event.end);
    const eventId = info.event.id;
  
    // Check for overlap with existing tasks and classes
    const isOverlapping = [...tasks, ...classes].some(event => {
      if (event.id === eventId) return false; // Skip the same event that is being resized
  
      const eventStart = moment(event.start);
      const eventEnd = moment(event.end);
  
      // Overlap check: new event start or end falls within an existing event time, or fully overlaps
      return (
        newStart.isBetween(eventStart, eventEnd, null, '[)') || 
        newEnd.isBetween(eventStart, eventEnd, null, '(]') || 
        (newStart.isBefore(eventStart) && newEnd.isAfter(eventEnd)) // full overlap
      );
    });
  
    if (isOverlapping) {
      // Prevent resize and show confirmation alert if there is an overlap
      confirmAlert({
        title: t('Time Slot Conflict'),
        message: t('The selected time overlaps with an existing class or event. Please choose a different time.'),
        buttons: [
          {
            label: t('Ok'),
            onClick: () => info.revert(), // Revert the event to its original size
          }
        ]
      });
      return; // Stop further execution if overlap exists
    }
  
    // Proceed with the confirmation and task update if there is no overlap
    confirmAlert({
      title: t('Confirm to submit'),
      message: t('Are you sure you want to resize the event?'),
      buttons: [
        {
          label: t('yes'),
          onClick: () => confirmEventChange(info),
        },
        {
          label: t('no'),
          onClick: () => info.revert(),
        },
      ],
    });
  };
  

  const confirmEventChange = async (info) => {
    let taskId = info.event.id;
  
    // Remove prefix from the ID before sending to backend
    taskId = taskId.replace(/^task-/, '').replace(/^class-/, '');
  
    const newStart = info.event.start;
    const newEnd = info.event.end;
    const formattedStart = moment(newStart).format('YYYY-MM-DD HH:mm:ss');
    const formattedEnd = newEnd ? moment(newEnd).format('YYYY-MM-DD HH:mm:ss') : null;
  
    const taskToEdit = tasks.find(task => task.id === `task-${taskId}`);
    if (!taskToEdit) {
      console.error('Task not found');
      info.revert();
      return;
    }
  
    const scheduleDay = moment(newStart).format('dddd'); // Get the day of the week
  
    const taskUpdateData = {
      title: taskToEdit.title,
      description: taskToEdit.description,
      start: formattedStart,
      end: formattedEnd,
      schedule_day: scheduleDay, // Include the schedule_day
    };
  
    try {
      await updateTask(taskId, taskUpdateData);
      fetchTasks();
    } catch (error) {
      console.error('Failed to update task', error);
      info.revert();
    }
  };
  
  const handleTasksChanged = () => {
    fetchTasks();
    setIsTaskPopupOpen(false);
  };

  const formatMinutesToHours = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours}h ${remainingMinutes}m`;
  };

  const renderEventContent = (eventInfo) => {
    return (
      <div className="event-content">
        {eventInfo.view.type === 'dayGridMonth' ? (
          <div className="event-title">
            {moment(eventInfo.event.start).format('HH:mm')} {eventInfo.event.title}
          </div>
        ) : (
          <>
            <div className="event-title">
              {moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}
            </div>
            <div>{eventInfo.event.title}</div>
            <div className="event-description">{eventInfo.event.extendedProps.description}</div>
            {eventInfo.event.extendedProps.schedule_hour !== undefined && (
              <div className="event-hours">{formatMinutesToHours(eventInfo.event.extendedProps.schedule_hour)}</div>
            )}
          </>
        )}
      </div>
    );
  };

  // Combine tasks and classes for the calendar events
  const events = [...tasks, ...classes];

  // Filter tasks for the current week
  const startOfWeek = moment().startOf('week');
  const endOfWeek = moment().endOf('week');
  const weekTasks = tasks.filter(task =>
    moment(task.start).isBetween(startOfWeek, endOfWeek, null, '[]')
  );

  // Sort tasks by start time (earliest first)
  weekTasks.sort((a, b) => moment(a.start) - moment(b.start));

  const combinedTasksAndClasses = [...tasks, ...classes]
  .filter(item => moment(item.start).isSameOrAfter(moment())) // Filter out past events
  .sort((a, b) => moment(a.start) - moment(b.start));

  // Filter for the most upcoming 5 tasks and classes
  const upcomingTasksAndClasses = combinedTasksAndClasses.slice(0, 5);

  return (
    <div className="calendar-layout">
      <div className="calendar-container">
        <div className="calendar-border">
          <FullCalendar
            contentHeight="auto"
            editable={true}
            droppable={true}
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
            headerToolbar={{
              left: 'prev,next today',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay',
            }}
            initialView="dayGridMonth"
            selectable={true}
            locale={i18n.language === 'th' ? thLocale : 'en'} // Apply the locale
            selectMirror={true}
            select={handleDateClick}
            events={events}
            eventClick={handleEventClick}
            eventDrop={handleEventDrop}
            eventResize={handleEventResize}
            eventResizableFromStart={true}
            eventDurationEditable={true}
            allDaySlot={false} // Disable the 'All Day' slot
            slotLabelFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false // Use 24-hour format
            }}
            eventTimeFormat={{
              hour: '2-digit',
              minute: '2-digit',
              hour12: false // Use 24-hour format
            }}
            slotMinTime="06:00:00"
            eventContent={renderEventContent}
            eventClassNames={(info) => {
              if (info.event.extendedProps.type === 'class') {
                return ['class-event'];
              } else {
                return ['task-event'];
              }
            }}
          />
        </div>
      </div>      
      <div className="task-list-container-side">
        <div className="task-list-side">
          {upcomingTasksAndClasses.map(item => (
            <div key={item.id} className={`task-item-side ${item.type === 'class' ? 'class-item' : 'task-item'}`} onClick={() => handleEventClick({ event: item })}>
              <div className="task-content">
                <div className="task-info">
                  <h3>{item.title}</h3>
                  <div className="task-details">
                    <div className="task-detail">
                      <FontAwesomeIcon icon={faCalendarAlt} className="icon" />
                      <span className="task-time">
                        {moment(item.start).locale(i18n.language).format('LL')} {/* Full Date format in locale */}
                      </span>
                    </div>
                    <div className="task-detail">
                      <FontAwesomeIcon icon={faClock} className="icon" />
                      <span className="task-time">
                        {moment(item.start).locale(i18n.language).format('LT')} - {moment(item.end).locale(i18n.language).format('LT')} {/* Time format in locale */}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="task-badge"><FontAwesomeIcon icon={faTasks} className="icon" /></div>
              </div>
            </div>
          ))}
        </div>
      </div>

      {isTaskPopupOpen && (
        <div className="side-popup">
          <TaskPopup
            isOpen={isTaskPopupOpen}
            onClose={() => setIsTaskPopupOpen(false)}
            onSave={handleTasksChanged}
            task={selectedTask}
            date={dateSelected}
            tasks={tasks}  // Pass tasks as prop
            classes={classes} // Pass classes as prop
          />
        </div>
      )}

    </div>
  );
};

export default Calendar;
