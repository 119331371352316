import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import './Registrations.css';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const testTypes = [
  { name: "GED", subtypes: ["Mathematical Reasoning", "Reasoning Through Language Arts", "Science", "Social Studies"] },
  { name: "IGCSE", subtypes: ["Mathematics", "English", "Sciences (Biology, Chemistry, Physics)", "History", "Geography", "Foreign Languages", "Art & Design", "Business Studies", "Computer Science"] },
  { name: "A-LEVEL", subtypes: ["Mathematics", "Further Mathematics", "Physics", "Chemistry", "Biology", "English Literature", "History", "Geography", "Economics", "Psychology"] },
  { name: "SAT", subtypes: ["SAT (General)", "SAT Subject Tests"] },
  { name: "IELTS", subtypes: ["IELTS Academic", "IELTS General Training"] },
  { name: "AP", subtypes: ["AP Calculus AB", "AP Calculus BC", "AP Physics", "AP Chemistry", "AP Biology", "AP English Language and Composition", "AP English Literature and Composition", "AP U.S. History", "AP World History", "AP Psychology"] },
  { name: "IB", subtypes: ["IB Mathematics", "IB Physics", "IB Chemistry", "IB Biology", "IB English A", "IB English B", "IB History", "IB Economics", "IB Psychology", "Theory of Knowledge (TOK)"] },
  { name: "TOEFL", subtypes: ["TOEFL iBT", "TOEFL PBT"] },
  { name: "TOEFL-MUIC", subtypes: ["General", "IBT"] },
  { name: "CU-AAT", subtypes: ["ธรรมดา", "E-Testing"] },
  { name: "CU-ATS", subtypes: ["Physics", "Chemistry"] },
  { name: "CU-TEP", subtypes: ["ธรรมดา + Speaking", "E-Testing", "E-Testing + CU-TEP E-Testing Speaking"] },
  { name: "TU-GET", subtypes: ["PBT", "CBT"] },
  { name: "OTHERS", subtypes: ["OTHERS"] }
];

const getDifferenceInDays = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
  };

const BookTest = () => {
    const { t, i18n } = useTranslation();
    const [bookings, setBookings] = useState([]);
    const [students, setStudents] = useState([]);
    const [shownTests, setShownTests] = useState([]);
    const [formData, setFormData] = useState({
      student_id: '',
      test_type: '',
      sub_type: '',
      test_date: '',
      test_details: '',
      price: '',
      paid_status: 'unpaid',
    });
    const [error, setError] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(100); // Set items per page
    const [showModal, setShowModal] = useState(false); // Control modal visibility
    const API_URL = process.env.REACT_APP_API_URL;

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
      };
    
  // Pagination logic
const indexOfLastBooking = currentPage * itemsPerPage;
const indexOfFirstBooking = indexOfLastBooking - itemsPerPage;
const currentBookings = bookings.slice(indexOfFirstBooking, indexOfLastBooking);
const totalPages = Math.ceil(bookings.length / itemsPerPage);

const paginate = (pageNumber) => setCurrentPage(pageNumber);
const handleNextPage = () => currentPage < totalPages && setCurrentPage(currentPage + 1);
const handlePrevPage = () => currentPage > 1 && setCurrentPage(currentPage - 1);
  


const fetchBookings = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test-scores/test-bookings`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const currentDate = new Date();

      // Sort bookings by test_date, with future dates first
      const sortedBookings = response.data.sort((a, b) => new Date(a.test_date) - new Date(b.test_date));

      // Filter to show only future bookings on this page
      const futureBookings = sortedBookings.filter(booking => new Date(booking.test_date) >= currentDate);

      setBookings(futureBookings);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  }, [API_URL]); // Add API_URL as a dependency if it's not constant

  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(response.data.students);  
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]); // Add API_URL as a dependency if it's not constant

  useEffect(() => {
    fetchBookings();
    fetchStudents();
  }, [fetchBookings, fetchStudents]);
  

  useEffect(() => {
    const today = new Date();
  
    // Test date formatting function
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const options = { day: '2-digit', month: 'short', year: 'numeric' };
      return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
    };
  
    // Filter bookings for upcoming tests (1 day and 3 days ahead)
    const upcomingTests = bookings.filter(booking => {
      const testDate = new Date(booking.test_date);
      const daysDifference = getDifferenceInDays(today, testDate);
  
       // Show toast only for tests that are exactly 1 day ahead
    return (daysDifference === 1) && !shownTests.includes(booking.id);
  });
  
    if (upcomingTests.length > 0) {
      upcomingTests.forEach(test => {
        const message = `${test.nickname} has ${test.test_type} (${test.sub_type}) test scheduled on ${formatDate(test.test_date)}.`;
        
        toast.info(message, {
          position: "top-right",
          autoClose: true,
          hideProgressBar: false,
          closeOnClick: false,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
  
        // Add the test ID to shownTests to avoid duplicates
        setShownTests((prevShown) => [...prevShown, test.id]);
      });
    }
  }, [bookings, i18n.language, shownTests]); // Add shownTests to the dependency array
  
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    confirmAlert({
      title: 'Confirm Test Booking',
      message: 'Are you sure you want to submit this test booking?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.post(`${API_URL}/test-scores/book-test`, formData, {
                headers: { Authorization: `Bearer ${token}` }
              });
  
              // Log the response from the backend
  
              // Reset form data
              setFormData({
                student_id: '',
                test_type: '',
                sub_type: '',
                test_date: '',
                test_details: '',
                price: '',
                paid_status: 'unpaid'
              });
  
              setError('');
              fetchBookings(); // Refresh bookings after submission
              setShowModal(false); // Close the modal on successful submission
            } catch (error) {
              console.error('Error booking test:', error);
              setError(t('Error booking test'));
            }
          }
        },
        {
          label: 'No',
        }
      ]
    });
  };
  
  
  
    // Handle form field changes
    const handleChange = (e) => {
      const { name, value } = e.target;
      setFormData((prevData) => ({
        ...prevData,
        [name]: value
      }));
    };

    const handleStatusUpdate = async (id) => {
        confirmAlert({
          title: 'Confirm to submit',
          message: 'Are you sure you want to mark this test as paid?',
          buttons: [
            {
              label: 'Yes',
              onClick: async () => {
                try {
                  const token = localStorage.getItem('token');
                  await axios.put(`${API_URL}/test-scores/update-status/${id}`, {}, {
                    headers: { Authorization: `Bearer ${token}` }
                  });
                  fetchBookings(); // Refresh the bookings list
                } catch (error) {
                  console.error('Error updating status:', error);
                }
              }
            },
            {
              label: 'No',
            }
          ]
        });
      };
      
  
    // Get subtypes for the selected test type
    const getSubtypes = () => {
      const selectedTestType = testTypes.find((type) => type.name === formData.test_type);
      return selectedTestType ? selectedTestType.subtypes : [];
    };
  
    return (
        <div className="test-score-container">
                <ToastContainer
                        position="top-right"
                        autoClose={false}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick={false}
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        />
          <Sidebar />
          <Header />
          <div className="test-score-content">
            <button className="open-modal-button" onClick={() => setShowModal(true)}>
              {t('Register for a Test')}
            </button>
    
            {showModal && (
              <div className="modal-overlay">
                <div className="modal-content">
                  <div className="modal-close-icon" onClick={() => setShowModal(false)}>
                    <FontAwesomeIcon icon={faTimes} />
                  </div>
                  <form className="test-score-form" onSubmit={handleSubmit}>
                    <div className="form-group">
                      <label>{t('Student')}</label>
                      <select 
                        name="student_id" 
                        value={formData.student_id} 
                        onChange={handleChange} 
                        required
                      >
                        <option value="" disabled>{t('Select Student')}</option>
                        {Array.isArray(students) && students.map(student => (
                          <option key={student.userid} value={student.userid}>
                            {`${student.firstname} ${student.lastname} (${student.nickname})`}
                          </option>
                        ))}
                      </select>
                    </div>
    
                    <div className="form-group">
                      <label>{t('Test Type')}</label>
                      <select 
                        name="test_type" 
                        value={formData.test_type} 
                        onChange={handleChange} 
                        required
                      >
                        <option value="" disabled>{t('Select Test Type')}</option>
                        {testTypes.map((type) => (
                          <option key={type.name} value={type.name}>
                            {type.name}
                          </option>
                        ))}
                      </select>
                    </div>
    
                    {formData.test_type && (
                      <div className="form-group">
                        <label>{t('subject')}</label>
                        <select 
                          name="sub_type" 
                          value={formData.sub_type} 
                          onChange={handleChange} 
                        >
                          <option value="" disabled>{t('Select Sub Type')}</option>
                          {getSubtypes().map((subtype, index) => (
                            <option key={index} value={subtype}>
                              {subtype}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}
    
                    <div className="form-group">
                      <label>{t('Test Date')}</label>
                      <input 
                        type="date" 
                        name="test_date" 
                        value={formData.test_date} 
                        onChange={handleChange} 
                        required 
                      />
                    </div>
    
                    <div className="form-group">
                      <label>{t('Test Details')}</label>
                      <textarea
                        name="test_details"
                        value={formData.test_details}
                        onChange={handleChange}
                        rows="4"
                        placeholder={t('Enter test details')}
                      />
                    </div>
    
                    <div className="form-group">
                      <label>{t('Price')}</label>
                      <input 
                        type="number" 
                        name="price" 
                        value={formData.price} 
                        onChange={handleChange} 
                      />
                    </div>
    
                    <div className="form-group">
                      <label>{t('Paid Status')}</label>
                      <select 
                        name="paid_status" 
                        value={formData.paid_status} 
                        onChange={handleChange}
                      >
                        <option value="unpaid">{t('Unpaid')}</option>
                        <option value="paid">{t('Paid')}</option>
                      </select>
                    </div>
    
                    {error && <div className="error-message">{error}</div>}
                    <button type="submit" className="submit-button">{t('Submit Booking')}</button>
                  </form>
                </div>
              </div>
            )}
    
            <h2>{t('All Test Bookings')}</h2>

            {/* Pagination Controls */}
            <div className="pagination-container">
              <button className="page-button" onClick={handlePrevPage} disabled={currentPage === 1}>{t('Prev')}</button>
              {Array.from({ length: totalPages }, (_, i) => (
                <button
                  key={i + 1}
                  onClick={() => paginate(i + 1)}
                  className={`page-button currentPage === i + 1 ? 'active' : ''`}
                >
                  {i + 1}
                </button>
              ))}
              <button className="page-button" onClick={handleNextPage} disabled={currentPage === totalPages}>{t('Next')}</button>
            </div>
            <table className="student-classes-table">
              <thead>
                <tr>

                 <th>{t('Test Date')}</th>
                  <th>{t('student')}</th>
                  <th>{t('nickname')}</th>
                  <th>{t('test')}</th>
                  <th>{t('subject')}</th>
                  <th>{t('price')}</th>
                  <th>{t('details')}</th>
                  <th>{t('status')}</th>
                  <th>{t('update')}</th>
                </tr>
              </thead>
              <tbody>
                    {currentBookings.map((booking, index) => (
                  <tr key={index}>
                    <td>{formatDate(booking.test_date)}</td>
                    <td>{booking.firstname} {booking.lastname}</td>
                    <td>{booking.nickname}</td>
                    <td>{booking.test_type}</td>
                    <td>{booking.sub_type}</td>
                    <td>{booking.price}</td>
                    <td>{booking.test_details}</td>
                    <td style={{ color: booking.paid_status === 'paid' ? 'green' : 'red' }}>
                    {booking.paid_status}
                    </td>
                    <td>
                        {booking.paid_status === 'unpaid' && (
                        <button className="paid-button" onClick={() => handleStatusUpdate(booking.id)}>
                            {t('Mark as Paid')}
                        </button>
                        )}
                    </td>
                    </tr>
                ))}
                </tbody>

            </table>
          </div>
        </div>
      );
    };
    
    export default withRoleAccess(BookTest, ['admin', 'superadmin']);