import React, { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import withRoleAccess from '../../hoc/withRoleAccess';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import Sidebar from '../../components/Sidebar/Sidebar';
import Header from '../../components/Header/Header';
import { confirmAlert } from 'react-confirm-alert'; 
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import './Registrations.css';

// Main test types and their associated subtypes
const testTypes = [
  { name: "GED", subtypes: ["Mathematical Reasoning", "Reasoning Through Language Arts", "Science", "Social Studies"] },
  { name: "IGCSE", subtypes: ["Mathematics", "English", "Sciences (Biology, Chemistry, Physics)", "History", "Geography", "Foreign Languages", "Art & Design", "Business Studies", "Computer Science"] },
  { name: "A-LEVEL", subtypes: ["Mathematics", "Further Mathematics", "Physics", "Chemistry", "Biology", "English Literature", "History", "Geography", "Economics", "Psychology"] },
  { name: "SAT", subtypes: ["SAT (General)", "SAT Subject Tests"] },
  { name: "IELTS", subtypes: ["IELTS Academic", "IELTS General Training"] },
  { name: "AP", subtypes: ["AP Calculus AB", "AP Calculus BC", "AP Physics", "AP Chemistry", "AP Biology", "AP English Language and Composition", "AP English Literature and Composition", "AP U.S. History", "AP World History", "AP Psychology"] },
  { name: "IB", subtypes: ["IB Mathematics", "IB Physics", "IB Chemistry", "IB Biology", "IB English A", "IB English B", "IB History", "IB Economics", "IB Psychology", "Theory of Knowledge (TOK)"] },
  { name: "TOEFL", subtypes: ["TOEFL iBT", "TOEFL PBT"] },
  { name: "TOEFL-MUIC", subtypes: ["General", "IBT"] },
  { name: "CU-AAT", subtypes: ["ธรรมดา", "E-Testing"] },
  { name: "CU-ATS", subtypes: ["Physics", "Chemistry"] },
  { name: "CU-TEP", subtypes: ["ธรรมดา + Speaking", "E-Testing", "E-Testing + CU-TEP E-Testing Speaking"] },
  { name: "TU-GET", subtypes: ["PBT", "CBT"] },
  { name: "OTHERS", subtypes: [] }
];

const TestScore = () => {  
  const { i18n } = useTranslation();
  const [testScores, setTestScores] = useState([]);
  const [students, setStudents] = useState([]);
  const [formData, setFormData] = useState({
    test_date: '',
    test_name: '',
    score: '',
    type: '',
    sub_type: '', 
    student_id: '',
    note: ''
  });
  const [error, setError] = useState('');
  const [showModal, setShowModal] = useState(false); // Control modal visibility
  const { t } = useTranslation();
  const API_URL = process.env.REACT_APP_API_URL;

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Intl.DateTimeFormat(i18n.language, options).format(date).toUpperCase();
  };

  // Fetch test scores using useCallback
  const fetchTestScores = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/test-scores`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setTestScores(response.data);
    } catch (error) {
      console.error('Error fetching test scores:', error);
    }
  }, [API_URL]);

  // Fetch students using useCallback
  const fetchStudents = useCallback(async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.get(`${API_URL}/students`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setStudents(response.data.students);  
    } catch (error) {
      console.error('Error fetching students:', error);
    }
  }, [API_URL]);

  useEffect(() => {
    fetchTestScores();
    fetchStudents();
  }, [fetchTestScores, fetchStudents]); // Add dependencies here

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    confirmAlert({
      title: 'Confirm Submission',
      message: 'Are you sure you want to submit this test score?',
      buttons: [
        {
          label: 'Yes',
          onClick: async () => {
            try {
              const token = localStorage.getItem('token');
              await axios.post(`${API_URL}/test-scores`, formData, {
                headers: { Authorization: `Bearer ${token}` }
              });
              setFormData({
                test_date: '',
                test_name: '',
                score: '',
                type: '',
                sub_type: '',
                student_id: '',
                note: ''
              });
              setError('');
              fetchTestScores(); 
              setShowModal(false); 
            } catch (error) {
              console.error('Error submitting test score:', error);
              setError(t('Error submitting test score'));
            }
          }
        },
        {
          label: 'No',
        }
      ]
    });
  };
  

  // Handle form field changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value
    }));
  };

  // Get the subtypes for the selected test type
  const getSubtypes = () => {
    const selectedTestType = testTypes.find((type) => type.name === formData.type);
    return selectedTestType ? selectedTestType.subtypes : [];
  };

  return (
    <div className="test-score-container">
      <Sidebar />
      <Header />

      <div className="test-score-content">
        <button className="open-modal-button" onClick={() => setShowModal(true)}>
          {t('Add Student Score')}
        </button>

        {showModal && (
          <div className="modal-overlay">
            <div className="modal-content">
              <div className="modal-close-icon" onClick={() => setShowModal(false)}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
              <form className="test-score-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <label>{t('Student')}</label>
                  <select 
                    name="student_id" 
                    value={formData.student_id} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="" disabled>{t('Select Student')}</option>
                    {Array.isArray(students) && students.map(student => (
                      <option key={student.userid} value={student.userid}>
                        {`${student.firstname} ${student.lastname} (${student.nickname})`}
                      </option>
                    ))}
                  </select>
                </div>

                <div className="form-group">
                  <label>{t('Test Date')}</label>
                  <input 
                    type="date" 
                    name="test_date" 
                    value={formData.test_date} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="form-group">
                  <label>{t('Test Type')}</label>
                  <select 
                    name="type" 
                    value={formData.type} 
                    onChange={handleChange} 
                    required
                  >
                    <option value="" disabled>{t('Select Test Type')}</option>
                    {testTypes.map((type) => (
                      <option key={type.name} value={type.name}>
                        {type.name}
                      </option>
                    ))}
                  </select>
                </div>

                {formData.type && (
                  <div className="form-group">
                    <label>{t('Subject')}</label>
                    <select 
                      name="sub_type"  // Fixed field name
                      value={formData.sub_type} 
                      onChange={handleChange} 
                      required
                    >
                      <option value="" disabled>{t('Select Subject')}</option>
                      {getSubtypes().map((subtype, index) => (
                        <option key={index} value={subtype}>
                          {subtype}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                <div className="form-group">
                  <label>{t('Details')}</label>
                  <input 
                    type="text" 
                    name="test_name" 
                    value={formData.test_name} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="form-group">
                  <label>{t('Score')}</label>
                  <input 
                    type="text" 
                    name="score" 
                    value={formData.score} 
                    onChange={handleChange} 
                    required 
                  />
                </div>

                <div className="form-group">
                  <label>{t('Note')}</label>
                  <textarea
                    name="note"
                    value={formData.note}
                    onChange={handleChange}
                    rows="4"
                    placeholder={t('Enter any additional notes')}
                  />
                </div>

                {error && <div className="error-message">{error}</div>}
                <button type="submit" className="submit-button">{t('Submit Test Score')}</button>
              </form>
            </div>
          </div>
        )}

        <h2>{t('All Test Scores')}</h2>
        <table className="student-classes-table">
          <thead>
            <tr>
              <th>{t('student')}</th>
              <th>{t('nickname')}</th>
              <th>{t('Test Date')}</th>
              <th>{t('type')}</th>
              <th>{t('subject')}</th>
              <th>{t('details')}</th>
              <th>{t('score')}</th>
              <th>{t('note')}</th>
            </tr>
          </thead>
          <tbody>
            {testScores.map((score, index) => (
              <tr key={index}>
                <td>{score.firstname} {score.lastname}</td>
                <td>{score.nickname}</td>
                <td>{formatDate(score.test_date)}</td>
                <td>{score.type}</td>
                <td>{score.sub_type}</td>
                <td>{score.test_name}</td>
                <td>{score.score}</td>
                <td>{score.note}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default withRoleAccess(TestScore, ['admin', 'superadmin']);
